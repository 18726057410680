<template>
  <div class="num">
    <div class="sq1 sq11" @click="decrease" v-if="showAdd">
      <span class="iconfont operate1" :class="{'icon-gray':disabled || decreaseDis}">&#xe8b1;</span>
      <span class="iconfont operate2" :class="{'icon-gray':disabled || decreaseDis}">&#xe8c5;</span>
    </div>
    <!-- 这里不能用v-model，只能用:value，因为这个值是从父组件传递过来的，props的数据是单向的，不是双向绑定的 -->
    <input 
      ref="input"
      v-show="showInp"
      :value="value" 
      :disabled="disabled"
      :placeholder="placeholder"
      type="number" 
      @input="input"
      @blur="blur"
      @keyup.enter="enter"
      @focus="focus"
      :style="{'padding-top':paddingTopBottom,'padding-bottom':paddingTopBottom}"
    />
    <div 
      @click="clickText" 
      v-show="!showInp" 
      class="text"
      :style="{'padding-top':paddingTopBottom,'padding-bottom':paddingTopBottom}"
      :class="{'gray':disabled}"
    >{{value}}</div>
    <div class="sq2 sq22" @click="increase" v-if="showAdd">
      <span class="iconfont operate1" :class="{'icon-gray':disabled || increaseDis}">&#xe6dd;</span>
      <span class="iconfont operate2" :class="{'icon-gray':disabled || increaseDis}">&#xe6df;</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddNumber',
  props: {
    value:{
      type: [Number],
      required: false,
      default: 0
    },
    paddingTopBottom:{
      type: [String],
      required: false,
      default: '8px'
    },
    placeholder:{
      type: [String],
      required: false,
      default: ''
    },
    step:{
      type: [Number],
      required: false,
      default: 1
    },
    disabled:{
      type: [Boolean],
      required: false,
      default: false
    },
    decreaseDis:{
      type: [Boolean],
      required: false,
      default: false
    },
    increaseDis:{
      type: [Boolean],
      required: false,
      default: false
    },
    showAdd:{
      type: [Boolean],
      required: false,
      default: true
    }
  },
  data(){
    return {
      showInp:false
    }
  },
  methods:{
    /* 
      点击框子,展示input框，所以变成true。这里做一个input框和div框之间的切换是为了解决页面渲染的问题
    */
    clickText(){
      if(this.disabled){
        return
      }
      this.showInp=true
      this.$nextTick(()=>{
        this.$refs.input.focus()
      })
    },
    decrease(){
      if(this.disabled || this.decreaseDis){
        return
      }
      this.$emit('click',Number(this.value-this.step))
    },
    increase(){
      if(this.disabled || this.increaseDis){
        return
      }
      this.$emit('click',Number(this.value+this.step))
    },
    input(e){
      this.$emit('input',Number(e.target.value))
    },
    blur(e){
      this.showInp=false
      this.$emit('blur',Number(e.target.value))
    },
    enter(){
      this.showInp=false
    },
    focus(e){
      this.$emit('focus',Number(e.target.value))
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../public/css/public.less');
.num{
  width:100%;
  position: relative;
  input{
    // background-color: orange;
    border-radius: 4px;
    width:100%;
    // height:36px;
    border:1px solid #dcdfe6;
    box-sizing: border-box;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    font-family: 'Noto Sans SC';
    font-size: 12px;
    color:#606266;
    &::-webkit-outer-spin-button,&::-webkit-inner-spin-button{
      -webkit-appearance: none!important;
    }
    &:focus{
      border-color: @mainColor;
    }
  }
  .text{
    color:#606266;
    font-family: 'Noto Sans SC';
    font-size: 12px;
    // height:36px;
    // width:100%;
    // height:100%;
    // background-color: red;
    border-radius: 4px;
    width:100%;
    border:1px solid #dcdfe6;
    box-sizing: border-box;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
  }
  .gray{
    background-color: #eee;
    color:#bbb;
    cursor:not-allowed;
  }
  .sq1,.sq2{
    position:absolute;
    color:@mainColor;
    text-align: center;
    user-select:none;
    .icon-gray{
      color:#bbb;
      cursor:not-allowed;
      &:hover{
        display: block!important;
        /* 这个意思是选择同级的opetate2的那个icon */
        &~.operate2{
          display: none!important;
        }
      }
    }
    .operate1{
      display: block;
    }
    .operate2{
      display: none;
    }
    &:hover{
      cursor: pointer;
      .operate1{
        display: none;
      }
      .operate2{
        display: block;
      }
    }
  }
  .sq11{
    left:6px;
    top:calc(50% - 10px);
    // background-color: red;
    .operate1,.operate2{
      font-size: 20px;
    }
  }
  .sq22{
    right:6px;
    top:calc(50% - 9px);
    .operate1,.operate2{
      font-size: 17px;
    }
  }
}
</style>
