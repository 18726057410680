<template>
  <div>
    <!-- 关联订单号 -->
    <div class="related-order-no" v-if="orderInfo.haveDsProduct && orderInfo.haveLedProduct">
      <span class="text">Related order number:</span>
      <span class="num">{{orderInfo.relOrderNo}}</span>
    </div>
    <!-- 蓝猫 ds产品 -->
    <div class="order-wrap" v-if="orderInfo.haveDsProduct">
      <div class="ci-title">CI Order</div>
      <!-- 提示 -->
      <div class="notice" v-if="orderInfo.poNumber">
        Your order {{orderInfo.poNumber}} is registered in our system and will be processed. A final confirmation will be available shortly within <a class="order-text" href="/#/myOrders">My Orders</a>. 
      </div>
      <!-- 客户信息 -->
      <div class="customer">
        <div class="title">
          {{L.customerInfo}}
        </div>
        <div class="content">
          <!-- sold to暂时先不展示 -->
          <div class="line" v-if="false">
            <div class="left">
              <!-- 售至 -->
              <span class="left-text1">{{L.soldTo}}:</span>
            </div>
            <div class="right">
              <span class="right-text1">{{orderInfo.soldTo && orderInfo.soldTo.addressTitle}} {{orderInfo.soldTo && orderInfo.soldTo.siteUseLocation}}</span>
              <span class="right-text2">{{orderInfo.soldTo && orderInfo.soldTo.addressInfo}}</span>
            </div>
          </div>
          <div class="line">
            <div class="left">
              <!-- 发货至 -->
              <span class="left-text1">{{L.shipTo}}:</span>
            </div>
            <div class="right">
              <span class="right-text1">{{orderInfo.shipTo && orderInfo.shipTo.addressTitle}} {{orderInfo.shipTo && orderInfo.shipTo.siteUseLocation}}</span>
              <span class="right-text2">{{orderInfo.shipTo && orderInfo.shipTo.addressInfo}}</span>
            </div>
          </div>
          <div class="line" v-if="orderInfo.deliveryTo">
            <div class="left">
              <!-- 送货方 -->
              <span class="left-text1">{{L.deliveryTo}}:</span>
            </div>
            <div class="right">
              <span class="right-text1">{{orderInfo.deliveryTo && orderInfo.deliveryTo.addressTitle}} {{orderInfo.deliveryTo && orderInfo.deliveryTo.siteUseLocation}}</span>
              <span class="right-text2">{{orderInfo.deliveryTo && orderInfo.deliveryTo.addressInfo}}</span>
            </div>
          </div>
          <div class="line">
            <div class="left">
              <!-- 开票至 -->
              <span class="left-text1">{{L.billTo}}:</span>
            </div>
            <div class="right">
              <span class="right-text1">{{orderInfo.billTo && orderInfo.billTo.addressTitle}} {{orderInfo.billTo && orderInfo.billTo.siteUseLocation}}</span>
              <span class="right-text2">{{orderInfo.billTo && orderInfo.billTo.addressInfo}}</span>
            </div>
          </div>
          <!-- payer暂时先不展示 -->
          <div class="line" v-if="false">
            <div class="left">
              <!-- 付款方 -->
              <span class="left-text1">{{L.payer}}:</span>
            </div>
            <div class="right">
              <span class="right-text1">{{orderInfo.payer && orderInfo.payer.addressTitle}} {{orderInfo.payer && orderInfo.payer.siteUseLocation}}</span>
              <span class="right-text2">{{orderInfo.payer && orderInfo.payer.addressInfo}}</span>
            </div>
          </div>
          <div class="line">
            <div class="left">
              <!-- 交货条款 -->
              <span class="left-text1">{{L.incoterms}}:</span>
            </div>
            <div class="right">
              <span class="right-text1">{{orderInfo.incoTerms}}</span>
            </div>
          </div>
          <div class="line">
            <div class="left">
              <!-- 付款条款 -->
              <span class="left-text1">{{L.paymentTerms}}:</span>
            </div>
            <div class="right">
              <span class="right-text1">{{orderInfo.paymentTerms}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 采购订单信息 -->
      <div class="customer">
        <div class="title">
          <!-- 采购订单信息 -->
          {{L.purchaseOrderInfo}}
        </div>
        <div class="content">
          <div class="line">
            <div class="left">
              <!-- 采购订单编号 -->
              <span class="left-text1">{{L.PONumber}}:</span>
            </div>
            <div class="right">
              <span class="right-text1">{{orderInfo.poNumber}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="customer">
        <div class="title">
          <!-- 订单备注 -->
          {{L.orderRemarks}}
        </div>
        <div class="content">
          <div class="line">
            <div class="left">
              <!-- 备注 -->
              <span class="left-text1">{{L.comment}}:</span>
            </div>
            <div class="right">
              <span class="right-text1">{{orderInfo.comment}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 产品概述 -->
      <div class="product-overview">
        <!-- 大标题 -->
        <div class="title">
          <!-- 订单详情 -->
          <span class="text">{{L.productOverview}}</span>
          <!-- orderltems -->
          <span class="quantity">{{orderInfo.dsProductItemCount}} {{L.orderltems}}</span>
        </div>

        <!-- 标准产品 -->
        <div class="standard-pro" v-show="orderList.length>0">
          <!-- <div class="standard-title">Standard Products</div> -->
          <!-- 一大行 -->
          <div class="line" v-for="item in orderList" :key="item.headVo && item.headVo.skuId">
            <!-- 内容标题 -->
            <div class="line-title">
              <div class="title-left"></div>
              <div class="title-right">
                <!-- 需求的交货日期 -->
                <div class="date">{{L.requestedDeliveryDate}}:</div>
                <!-- 数量 -->
                <div class="quantity">{{L.quantity}}:</div>
                <div class="price"></div>
              </div>      
            </div>
            <!-- 主内容 -->
            <div class="main-content">
              <!-- 左边的 -->
              <div class="left">
                <div class="info">
                  <div class="image" :class="{'no-image':item.ifVisible!=1}">
                    <img v-show="item.ifVisible==1" :src="item.headVo.loopImageList && item.headVo.loopImageList[0]" alt="">
                  </div>
                  <div class="text">
                    <div class="text1">
                      <a v-if="item.canJump!=1" class="span1" href="javascript:;" @click="handleSkuName">{{item.headVo && item.headVo.skuName}}</a>
                      <a v-if="item.canJump==1" class="span1" :href="detailUrl(1,item.eanCode)">
                        <span>{{item.headVo && item.headVo.skuName}}</span>
                        <span class="phase-out" v-if="item.headVo && item.headVo.phaseOut">({{item.headVo && item.headVo.phaseOut}})</span>
                      </a>
                    </div>
                    <div class="text2">{{item.headVo && item.headVo.eanOrIc}} {{item.headVo && item.headVo.skuId}}</div>
                    <!-- 小图标 -->
                    <div class="small-pic" v-show="item.ifVisible==1">
                      <img class="img img1" :src="item.headVo && item.headVo.energyLogoImage" v-show="item.headVo && item.headVo.energyLogoImage"/>
                      <img class="big-img" :src="item.headVo && item.headVo.energyBigImage"/>
                      <img class="img" v-for="item2 in item.headVo.logoImageList" :key="item2" :src="item2"/>
                      <div class="icons" v-show="item.headVo.dataSheetLink">
                        <span class="iconfont icon-file" @click="downloadProduct(item.headVo.dataSheetLink,1)">&#xe60a;</span>
                        <span class="iconfont icon-download" @click="downloadProduct(item.headVo.dataSheetLink,0)">&#xe61c;</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 右边的一行 -->
              <div class="right">
                <div class="right-line" v-for="item2 in item.linesVo" :key="item2.lineDetailId">
                  <!-- 基本内容 -->
                  <div class="basic-info">
                    <div class="date">
                      <span class="iconfont icon-date">&#xe807;</span>
                      <span class="text-date">{{formatDate(item2.deliveryDate)}}</span>
                    </div>
                    <div class="quantity">
                      {{item2.skuCount}}
                    </div>
                    <div class="price">
                      <template v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SP')">
                        <div class="left-price" v-show="item2.skuNewAmount">
                          <div v-show="item2.skuAmountStatus=='A' || !Boolean(item2.skuAmountStatus)">
                            <div class="price-num">
                              {{formatMoney(item2.skuNewAmount,item2.customerCurrency)}}
                            </div>
                            <div class="carton-box">({{formatMoney(item2.skuPrice,item2.currency)}} {{L.per}} {{item2.assemblyType}})</div>
                          </div>
                          <div v-show="item2.skuAmountStatus=='B'">
                            <div class="new-price">
                              <el-tooltip 
                                class="item" 
                                effect="dark"
                                placement="top"
                              >
                                <div slot="content">
                                  <div v-for="item3,i in item2.discountVos" :key="i">
                                    {{item3.discountType}}:&nbsp;&nbsp;{{item3.discountPct}}&nbsp;&nbsp;{{item3.ifMinus?'-':''}} {{formatMoney(Math.abs(item3.discountAmount))}}
                                  </div>
                                </div>
                                <span class="iconfont icon-info">&#xed50;</span>
                              </el-tooltip>
                              {{formatMoney(item2.skuNewAmount,item2.customerCurrency)}}
                            </div>
                            <div class="carton-box">({{formatMoney(item2.skuPrice,item2.currency)}} {{L.per}} {{item2.assemblyType}})</div>
                          </div>
                          <div v-show="item2.skuAmountStatus=='C'">
                            <div class="price-num2">{{formatMoney(item2.skuOldAmount,item2.customerCurrency)}}</div>
                            <div class="carton-box">({{formatMoney(item2.skuPrice,item2.currency)}} {{L.per}} {{item2.assemblyType}})</div>
                            <div class="new-price">
                              <el-tooltip 
                                class="item" 
                                effect="dark"
                                placement="top"
                              >
                                <div slot="content">
                                  <div v-for="item3,i in item2.discountVos" :key="i">
                                    {{item3.discountType}}:&nbsp;&nbsp;{{item3.discountPct}}&nbsp;&nbsp;{{item3.ifMinus?'-':''}} {{formatMoney(Math.abs(item3.discountAmount))}}
                                  </div>
                                </div>
                                <span class="iconfont icon-info">&#xed50;</span>
                              </el-tooltip>
                              {{formatMoney(item2.skuNewAmount,item2.customerCurrency)}}
                            </div>
                          </div>
                        </div>
                        <!-- 无论是null 还是'' 还是0 布尔值都是false，只要是false，就要展示Price on request -->
                        <div class="left-price" v-show="!item2.skuNewAmount">
                          <!-- 价格需要申请 -->
                          <div class="carton-box">{{L.priceRequest}}</div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <!-- 装箱内容 -->
                  <div class="carton-box-remark" v-show="item2.qtyMsgList.length>=1 && item.ifVisible==1">
                    <div class="line-title">
                      <div class="date"></div>
                      <div class="quantity">
                        <div class="line-one">
                          <span 
                            class="iconfont icon-arrow"
                            :class="{'icon-active':item2.showCarton}"
                            @click="clickCartonFold(item,item2,1)"
                          >&#xe66c;</span>
                          <span class="text">{{item2.qtyMsgList[0]}}</span>
                        </div>
                        <transition
                          @enter="enter"
                          @afterEnter="afterEnter"
                          @leave="leave"
                          @afterLeave="afterLeave"
                        >
                        <div v-show="item2.showCarton" class="line-more-wrap">
                          <div class="line-more" v-for="item3,i in item2.qtyMsgList" :key="i">{{i>=1?item3:null}}</div>
                        </div>
                        </transition>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- quote产品 -->
        <div class="standard-pro" v-show="quoteOrderList.length>0" v-for="obj in quoteOrderList" :key="obj.quoteHeadId">
          <div class="standard-title">Quote {{obj.quoteNumber}}</div>
          <!-- 一大行 -->
          <div class="line" v-for="item in obj.quoteItems" :key="item.headVo && item.headVo.skuId">
            <!-- 内容标题 -->
            <div class="line-title">
              <div class="title-left"></div>
              <div class="title-right">
                <!-- 需求的交货日期 -->
                <div class="date">{{L.requestedDeliveryDate}}:</div>
                <!-- 数量 -->
                <div class="quantity">{{L.quantity}}:</div>
                <div class="price"></div>
              </div>      
            </div>
            <!-- 主内容 -->
            <div class="main-content">
              <!-- 左边的 -->
              <div class="left">
                <div class="info">
                  <div class="image" :class="{'no-image':item.ifVisible!=1}">
                    <img v-show="item.ifVisible==1" :src="item.headVo.loopImageList && item.headVo.loopImageList[0]" alt="">
                  </div>
                  <div class="text">
                    <div class="text1">
                      <a v-if="item.canJump!=1" class="span1" href="javascript:;" @click="handleSkuName">{{item.headVo && item.headVo.skuName}}</a>
                      <a v-if="item.canJump==1" class="span1" :href="detailUrl(1,item.eanCode)">
                        <span>{{item.headVo && item.headVo.skuName}}</span>
                        <span class="phase-out" v-if="item.headVo && item.headVo.phaseOut">({{item.headVo && item.headVo.phaseOut}})</span>
                      </a>
                    </div>
                    <div class="text2">{{item.headVo && item.headVo.eanOrIc}} {{item.headVo && item.headVo.skuId}}</div>
                    <!-- 小图标 -->
                    <div class="small-pic" v-show="item.ifVisible==1">
                      <img class="img img1" :src="item.headVo && item.headVo.energyLogoImage" v-show="item.headVo && item.headVo.energyLogoImage"/>
                      <img class="big-img" :src="item.headVo && item.headVo.energyBigImage"/>
                      <img class="img" v-for="item2 in item.headVo.logoImageList" :key="item2" :src="item2"/>
                      <div class="icons" v-show="item.headVo.dataSheetLink">
                        <span class="iconfont icon-file" @click="downloadProduct(item.headVo.dataSheetLink,1)">&#xe60a;</span>
                        <span class="iconfont icon-download" @click="downloadProduct(item.headVo.dataSheetLink,0)">&#xe61c;</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 右边的一行 -->
              <div class="right">
                <div class="right-line" v-for="item2 in item.linesVo" :key="item2.lineDetailId">
                  <!-- 基本内容 -->
                  <div class="basic-info">
                    <div class="date">
                      <span class="iconfont icon-date">&#xe807;</span>
                      <span class="text-date">{{formatDate(item2.deliveryDate)}}</span>
                    </div>
                    <div class="quantity">
                      {{item2.skuCount}}
                    </div>
                    <div class="price">
                      <template v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SP')">
                        <div class="left-price" v-show="item2.skuNewAmount">
                          <div v-show="item2.skuAmountStatus=='A' || !Boolean(item2.skuAmountStatus)">
                            <div class="price-num">
                              {{formatMoney(item2.skuNewAmount,item2.customerCurrency)}}
                            </div>
                            <div class="carton-box">({{formatMoney(item2.skuPrice,item2.currency)}} {{L.per}} {{item2.assemblyType}})</div>
                          </div>
                          <div v-show="item2.skuAmountStatus=='B'">
                            <div class="new-price">
                              <el-tooltip 
                                class="item" 
                                effect="dark"
                                placement="top"
                              >
                                <div slot="content">
                                  <div v-for="item3,i in item2.discountVos" :key="i">
                                    {{item3.discountType}}:&nbsp;&nbsp;{{item3.discountPct}}&nbsp;&nbsp;{{item3.ifMinus?'-':''}} {{formatMoney(Math.abs(item3.discountAmount))}}
                                  </div>
                                </div>
                                <span class="iconfont icon-info">&#xed50;</span>
                              </el-tooltip>
                              {{formatMoney(item2.skuNewAmount,item2.customerCurrency)}}
                            </div>
                            <div class="carton-box">({{formatMoney(item2.skuPrice,item2.currency)}} {{L.per}} {{item2.assemblyType}})</div>
                          </div>
                          <div v-show="item2.skuAmountStatus=='C'">
                            <div class="price-num2">{{formatMoney(item2.skuOldAmount,item2.customerCurrency)}}</div>
                            <div class="carton-box">({{formatMoney(item2.skuPrice,item2.currency)}} {{L.per}} {{item2.assemblyType}})</div>
                            <div class="new-price">
                              <el-tooltip 
                                class="item" 
                                effect="dark"
                                placement="top"
                              >
                                <div slot="content">
                                  <div v-for="item3,i in item2.discountVos" :key="i">
                                    {{item3.discountType}}:&nbsp;&nbsp;{{item3.discountPct}}&nbsp;&nbsp;{{item3.ifMinus?'-':''}} {{formatMoney(Math.abs(item3.discountAmount))}}
                                  </div>
                                </div>
                                <span class="iconfont icon-info">&#xed50;</span>
                              </el-tooltip>
                              {{formatMoney(item2.skuNewAmount,item2.customerCurrency)}}
                            </div>
                          </div>
                        </div>
                        <!-- 无论是null 还是'' 还是0 布尔值都是false，只要是false，就要展示Price on request -->
                        <div class="left-price" v-show="!item2.skuNewAmount">
                          <!-- 价格需要申请 -->
                          <div class="carton-box">{{L.priceRequest}}</div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <!-- 装箱内容 -->
                  <div class="carton-box-remark" v-show="item2.qtyMsgList.length>=1 && item.ifVisible==1">
                    <div class="line-title">
                      <div class="date"></div>
                      <div class="quantity">
                        <div class="line-one">
                          <span 
                            class="iconfont icon-arrow"
                            :class="{'icon-active':item2.showCarton}"
                            @click="clickCartonFold(item,item2,2,obj)"
                          >&#xe66c;</span>
                          <span class="text">{{item2.qtyMsgList[0]}}</span>
                        </div>
                        <transition
                          @enter="enter"
                          @afterEnter="afterEnter"
                          @leave="leave"
                          @afterLeave="afterLeave"
                        >
                        <div v-show="item2.showCarton" class="line-more-wrap">
                          <div class="line-more" v-for="item3,i in item2.qtyMsgList" :key="i">{{i>=1?item3:null}}</div>
                        </div>
                        </transition>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        

      </div>
    </div>
    <!-- 蓝猫 LED产品 -->
    <div class="order-wrap" v-if="orderInfo.haveLedProduct">
      <div class="ci-title">SO Order</div>
      <!-- 提示 -->
      <div class="notice" v-if="orderInfo.poNumber">
        Your order {{orderInfo.ledOrderNo}} is registered in our system and will be processed. A final confirmation will be available shortly within <a class="order-text" href="/#/myOrders">My Orders</a>. 
      </div>
      <!-- 采购订单信息 -->
      <div class="customer">
        <div class="title">Order Inforamtion</div>
        <div class="content">
          <div class="line">
            <div class="left">
              <span class="left-text1">{{L.PONumber}}:</span>
            </div>
            <div class="right">
              <span class="right-text1">{{orderInfo.ledOrderNo}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 产品概述 -->
      <div class="product-overview">
        <!-- 大标题 -->
        <div class="title">
          <!-- 订单详情 -->
          <span class="text">{{L.productOverview}}</span>
          <!-- orderltems -->
          <span class="quantity">{{orderInfo.ledProductItemCount}} {{L.orderltems}}</span>
        </div>
        <div class="standard-pro" v-show="ledOrderList.length>0">
          <!-- 一大行 -->
          <div class="line line-led" v-for="item in ledOrderList" :key="item.lineDetailId">
            <div class="info-top">
              <div class="left">
                <div class="image"><img v-show="false"/></div>
                <div class="text">
                  <div class="text1"><span>{{item.skuName}}</span></div>
                  <div class="text2">
                    <span class="text2-title">Product No:</span>
                    <span class="text2-content">{{item.skuId}}</span>
                  </div>
                </div>
              </div>
              <div class="right">
                <div class="date">Quantity:</div>
                <div class="quantity">Price:</div>
                <div class="price"></div>
              </div>
            </div>
            <!-- 主内容 -->
            <div class="main-content">
              <!-- 左边的基本信息+联系人的基本信息 -->
              <div class="left">
                <div class="info">
                  <div class="info-wrap">
                    <div class="title-inp-wrapper">
                      <div class="title-wrap">
                        <span class="inp-title">Customer:</span>
                        <span class="inp-title">Contacts:</span>
                        <span class="inp-title">Phone:</span>
                        <span class="inp-title">Address:</span>
                      </div>
                      <div class="content-wrap">
                        <div class="inp-spyglass-wrap">
                          <div class="inp">{{item.finalCustomerName}}</div>
                        </div>
                        <div class="inp-spyglass-wrap">
                          <div class="inp">{{item.shipContact}}</div>
                        </div>
                        <div class="inp-spyglass-wrap">
                          <div class="inp">{{item.shipPhone}}</div>
                        </div>
                        <div class="inp-spyglass-wrap">
                          <div class="inp inp-textarea">{{item.shipAddress}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 右边的整块，从数量Quantity开始 -->
              <div class="right">
                <div class="right-line led-right-line">
                  <!-- 单价+数量+总价+图标那一行 -->
                  <div class="basic-info"> 
                    <div class="date normal-square">{{item.skuCount}}</div>
                    <div class="quantity normal-square">{{item.skuPrice}}</div>
                    <!-- 总价和图标 -->
                    <div class="price">
                      <template>
                        <div class="left-price">
                          <div class="price-num-wrap">
                            <span class="price-num">
                                {{formatMoney(item.skuAmount,item.customerCurrency)}}
                              </span>
                          </div>
                        </div>
                      </template>

                    </div>
                  </div>
                  <!-- 交期文字+交期日期+拆分文字那一行 -->
                  <div class="basic-info delivery-date-wrap"> 
                    <div class="date no-text-square">Request Delivery Date:</div>
                    <div class="quantity date-normal">
                      <span class="iconfont icon-date">&#xe807;</span>
                      <span class="text-date">{{formatDate(item.deliveryDate)}}</span>
                    </div>
                    <!-- 总价和图标 -->
                    <div class="price"></div>
                  </div>
                  <!-- 额外信息+报错 -->
                  <div class="enter-info">
                    <div class="left">
                      <div class="icon">
                        <span class="iconfont icon-arrow" :class="{'icon-active':item.showCarton}" @click="foldLedMaterial(item)">&#xe66c;</span>
                      </div>
                      <div class="title-wrap">
                        <span class="inp-title inp-title-main" @click="foldLedMaterial(item)">Customer Remark</span>
                        <transition
                          @enter="enter"
                          @afterEnter="afterEnter"
                          @leave="leave"
                          @afterLeave="afterLeave"
                        >
                          <div class="more-animatin" v-if="item.showCarton">
                            <span class="inp-title">Material Code:</span>
                            <span class="inp-title">Customer Contract:</span>
                            <span class="inp-title">Process No:</span>
                            <span class="inp-title">Box Remark:</span>
                          </div>
                        </transition>
                      </div>
                      <div class="content-wrap">
                        <div size="mini" class="not-show-inp inp"></div>
                        <transition
                          @enter="enter"
                          @afterEnter="afterEnter"
                          @leave="leave"
                          @afterLeave="afterLeave"
                        >
                        <div class="more-animatin" v-if="item.showCarton">
                          <div class="inp">{{item.materialCode}}</div>
                          <div class="inp">{{item.terminalContract}}</div>
                          <div class="inp">{{item.processOrder}}</div>
                          <div class="inp">{{item.boxRemark}}</div>
                        </div>
                        </transition> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {downFileGet} from '@/api/tool'
import Vue from 'vue'

export default {
  name:'Delivery',
  components:{
    
  },
  props:['orderInfo','orderList','quoteOrderList','ledOrderList'],
  data(){
    return {
      
    }
  },
  methods:{
    /* 折叠LED的material部分的信息 */
    foldLedMaterial(item){
      this.ledOrderList.forEach((val,i)=>{
        if(item.lineDetailId==val.lineDetailId){
          val.showCarton=!item.showCarton
          this.$set(this.ledOrderList,i,val) //触发渲染
        }
      })
    },
    /* 下载产品文件 */
    downloadProduct(src,flag){
      let url=src.includes('inventronicsglobal')?src.split(Vue.config.DAM_URL)[1]:src.split(Vue.config.OSR_URL)[1]
      url=this.decodeHtml('/'+url)
      this.$emit('openLoading')
      downFileGet(url).then(res=>{
        this.$emit('closeLoading')
        this.downloadFile(res,flag)
      }).catch(()=>{
        this.$emit('closeLoading')
      })
    },
    /* 点击展开与折叠装箱信息 */
    clickCartonFold(item,item2,flag,quoteObj){
      if(flag==1){
        this.orderList.forEach((val,i)=>{
          if(item.headVo.skuId===val.headVo.skuId){
            val.linesVo.forEach((val2,i2)=>{
              if(val2.lineDetailId===item2.lineDetailId){
                val.linesVo[i2].showCarton=!val.linesVo[i2].showCarton
                this.$set(this.orderList,i,val)
              }
            })
          }
        })
      }
      if(flag==2){
        this.quoteOrderList.forEach((val,i)=>{
          //找到第一层
          if(quoteObj.quoteHeadId===val.quoteHeadId){
            //找到第二层
            val.quoteItems.forEach((val2,i2)=>{
              if(val2.headVo.skuId===item.headVo.skuId){
                val2.linesVo.forEach((val3,i3)=>{
                  if(val3.lineDetailId===item2.lineDetailId){
                    val.quoteItems[i2].linesVo[i3].showCarton=!val.quoteItems[i2].linesVo[i3].showCarton
                    this.$set(this.quoteOrderList,i,val)
                  }
                })
              }
            })
          }
        })
      }
    },
    /* 如果ifVisible标记为0，点击skuName，报错提示 */
    handleSkuName(){
      this.$message.warning(this.L.sorryResult)
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../../../public/css/public.less');
@import url('./index.less');
</style>