import axios from 'axios'
import { Message } from 'element-ui'
import Vue from 'vue'
import router from '../router'
import {api_quick_order,api_quotation_cart,api_quotation_wishlist} from '@/api/api' //弹窗报错的接口
import {api_invoice_list,api_credit_list,api_debit_list,api_claim_rma_list} from '@/api/api' //成功取responseData的接口
import {api_download_pricelist} from '@/api/api' //文件失败取responseData的接口

let successCode = 200
//3010，403，400，token失效，跳转到登录页面
let tokenInvalidArr = ['3010','403','400']
let errorMsg = 'msg'
axios.defaults.baseURL = ''

function getHeaders(){
  let select_country = Vue.$cookies.get('select_country')
  let countryCode = ''
  if(select_country){
    countryCode=select_country.split(';')[0]
  }else{
    if(window.location.href.indexOf('Catalogue')!=-1){
      let arr = window.location.href.split('?')[1]
      let arr2 = arr.split('&')
      arr2.forEach((val)=>{
        if(val.indexOf('Catalogue')!=-1){
          countryCode=val.split('=')[1]
        }
      })
    }
  }
  return {
    languageId:Vue.$cookies.get('languageId'),
    token:Vue.$cookies.get('token'),
    country:countryCode,
    //有token说明是访客，没有token说明是游客。这个前端用不到的，前端直接根据token判断就好，主要是为了传给后端用的
    accessMode:Vue.$cookies.get('token')?'user':'visitor'
  }
}

function successCallback(resolve,reject,res,url){
  let responseData = res.data
  //如果是快速下单接口，那么成功的回调不要走默认的方法，自己定义即可。相当于不要默认展示报错信息，用单个api自己的方式去呈现报错信息（这个还没写）
  if(responseData.code==successCode){
    //大多数接口都是直接取responseData.data的数据就好了，但是有一部分接口，后端把业务层面的数据放在外层了，所以那部分接口不能从data里取，直接从responseData里取
    if(url==api_invoice_list || url==api_credit_list || url==api_debit_list || url==api_claim_rma_list){
      console.log('进来了???')
      resolve(responseData)
    }else{
      resolve(responseData.data,responseData)
    }
  }else{
    //不是200，并且code码是这几种情况就要去登录页面
    if(tokenInvalidArr.includes(String(responseData.code))){
      Vue.$cookies.remove('token')
      Vue.$cookies.remove('user_id')
      router.push({
        path:'/login'
      })
    }
    reject(responseData)
    //除了这几个接口，直接报错就行。这几个接口的报错，要用弹窗的方式报错的，所以不在这里报错了
    if(url!=api_quick_order && url!=api_quotation_cart && url!=api_quotation_wishlist){
      Message.error(responseData[errorMsg]) //后端代码错误
    }
  }
}
function failCallback(reject,res,url){
  let responseData = res.data
  //有些接口的错误信息要在业务代码里处理，所以把res丢过去，不要取res.data里的数据。(文件下载的报错里也没有data数据可以取)
  if(url==api_download_pricelist && res.response.status==304){
    reject(res)
    return
  }else{
    reject(responseData)
  }
  if(responseData){
    Message.error(responseData[errorMsg]) //请求本身错误（比如404之类的）
  }else{
    Message.error(res.message) //axios本身错误,比如500
  }
}

export function getAction(url,params){
  return new Promise((resolve,reject)=>{
    axios({
      method:'get',
      url:url,
      params:params,
      headers: getHeaders(),
    }).then(res=>{
      successCallback(resolve,reject,res)
    }).catch(res=>{
      failCallback(reject,res)
    })
  })
}

export function postAction(url,params){
  return new Promise((resolve,reject)=>{
    axios({
      method:'post',
      url:url,
      data:params,
      headers: getHeaders()
    }).then(res=>{
      successCallback(resolve,reject,res,url)
    }).catch(res=>{
      failCallback(reject,res)
    })
  })
}

export function postFormAction(url,params){
  let formData = new FormData();
  for(let key in params){
    formData.append(key,params[key])
  }
  return new Promise((resolve,reject)=>{
    axios({
      method:'post',
      url:url,
      data:formData,
      headers: getHeaders(),
    }).then(res=>{
      successCallback(resolve,reject,res)
    }).catch(res=>{
      failCallback(reject,res)
    })
  })
}

export function downFilePost(url,params){
  return new Promise((resolve,reject)=>{
    axios({
      method:'post',
      url:url,
      data:params,
      headers: getHeaders(),
      responseType: 'blob'
    }).then(res=>{
      resolve(res)
    }).catch(res=>{
      failCallback(reject,res,url)
    })
  })
}

export function downFileGet(url,params){
  return new Promise((resolve,reject)=>{
    axios({
      method:'get',
      url:url,
      params:params,
      headers: getHeaders(),
      responseType: 'blob'
    }).then(res=>{
      resolve(res)
    }).catch(res=>{
      failCallback(reject,res)
    })
  })
}

