<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap fieldFailuers-wrap">
      <!-- 导航 -->
      <div class="order-navigation">
        <a
          class="text1"
          href="/#/catalogue"
        >{{L.home}}</a>
        <span class="line">/</span>
        <!-- 我的订单 -->
        <a
          class="text1"
          href="/#/selectRMAtype" 
        >{{L.myService}}</a>
        <span class="line">/</span>

        <!-- 这部分是正常单据进来的路由 -->
        <template v-if="$route.query.from=='normal'">
          <a
            class="text2"
            href="/#/enquiry" 
          >{{L.commercialEnquiry}}</a>
        </template>
        <!-- 这部分是草稿单据的路由 -->
        <template v-if="$route.query.from=='draft'">
          <a
            class="text1"
            href="/#/rmaList" 
          >{{L.myTickets}}</a>
          <span class="line">/</span>
          <a
            class="text1"
            href="/#/draftsRMAlist" 
          >{{L.drafts}}</a>
          <span class="line">/</span>
          <a
            class="text2"
            :href="'/#/enquiry?flag=1&from=draft&draftNo='+$route.query.draftNo" 
          >{{$route.query.draftNo}}</a>
        </template>
      </div>
      <!-- 我的订单-标题 -->
      <div class="my-orders-title">
        <span>{{L.commercialEnquiry}}</span>
        <span class="draft-number" v-if="$route.query.from=='draft'">{{$route.query.draftNo}}</span>
      </div>
      <!-- 提交按钮 -->
      <div class="submit-wrap">
        <div class="submit" @click="handleSubmit(1)">
          <span class="iconfont icon2">&#xe747;</span>
          <span>{{L.submit}}</span>
        </div>
        <div class="submit" @click="handleSubmit(2)">
          <span class="iconfont icon3">&#xe695;</span>
          <span>{{L.saveAsDraft}}</span>
        </div>
        
      </div>
      <div class="line-title">
        <span class="text">{{L.generalData}}</span>
      </div>
      <!-- 单头 -->
      <el-form :model="formCommon" :rules="rules" ref="formCommon" class="form" :show-message="true">
      
        <div class="square" v-if="$route.query.from=='draft'">
          <span class="text">{{L.draftNo}}:</span>
          <el-form-item prop="caseNo">
            <el-input disabled v-model="formCommon.caseNo" class="inp-field"></el-input>
          </el-form-item>
        </div>

        <div class="square">
          <span class="text">{{L.createdDate}}:</span>
          <el-form-item prop="caseDate">
            <el-date-picker
              disabled
              v-model="formCommon.caseDate"
              class="inp-field"
              value-format="yyyy-MM-dd"
              format="dd-MM-yyyy"
              type="date">
            </el-date-picker>
          </el-form-item>
        </div>

        <div class="square">
          <span class="text">{{L.customerName}}:</span>
          <el-form-item>
            <el-input disabled v-model="customerName" class="inp-field"></el-input>
          </el-form-item>
        </div>

        <div class="square">
          <span class="text">{{L.customerNo}}:</span>
          <el-form-item>
            <el-input disabled v-model="customerAccountNo" class="inp-field"></el-input>
          </el-form-item>
        </div>

        <div class="square">
          <span class="text">{{L.customerContact}}:</span>
          <el-form-item>
            <el-input disabled v-model="contactName" class="inp-field"></el-input>
          </el-form-item>
        </div>

        <div class="square square-long">
          <span class="text">{{L.customerContactEmail}}:</span>
          <el-form-item>
            <el-input disabled v-model="contactEmail" class="inp-field inp-field-long"></el-input>
          </el-form-item>
        </div>

        <div class="square square-long">
          <span class="text additional-email">{{L.addAddiEmail}}: 
            <el-tooltip 
              class="upload-tooltip" 
              effect="dark"
              placement="top"
            >
              <div slot="content">Addresses will be notified about this ticket. Use semi-colon as separator and max. 255 characters.</div>
              <span class="iconfont icon-info">&#xed50;</span>
            </el-tooltip>
          </span>
          <el-form-item prop="crmEmailCopyTo">
            <!-- <el-input :autosize="{ minRows: 3}" type="textarea" v-model="formCommon.crmEmailCopyTo" class="inp-field inp-field-textarea" :placeholder="additionalPlace"></el-input> -->
            <el-input v-model="formCommon.crmEmailCopyTo" :placeholder="additionalPlace" class="inp-field inp-field-long"></el-input>
          </el-form-item>
        </div>

        <div class="square square-long">
          <span class="text">{{L.subject}} <span class="asterisk">*</span>:</span>
          <el-form-item prop="subject">
            <el-input v-model="formCommon.subject" class="inp-field inp-field-long"></el-input>
          </el-form-item>
        </div>

        <div></div>

        <div class="square square-long">
          <span class="text">{{L.description}} <span class="asterisk">*</span>:</span>
          <el-form-item prop="description">
            <el-input :autosize="{ minRows: 6}" type="textarea" v-model="formCommon.description" class="inp-field inp-field-textarea"></el-input>
          </el-form-item>
        </div>

      </el-form>
      

      <!-- 上传文件 -->
      <div class="upload-wrap">
        <el-upload
          :action="uploadApi"
          :multiple="false"
          :show-file-list="false"
          :http-request="handleUpload">
          <div class="upload-btn">
            <span class="iconfont icon-upload">&#xe63f;</span>
            <span class="upload-text">{{L.upload}}</span>
          </div>
        </el-upload>
        <div class="file-list">
          <div class="line" v-for="item in fileList" :key="item.fileId">
            <span class="iconfont icon-file2" @click="handleDeleteFile(item)">&#xec7b;</span>
            <span class="file-text" @click="handleDownFile(item)">{{item.fileName}}</span>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {
  api_claim_enquiry_add,
  api_claim_upload_file,
  api_claim_detail,
  api_delete_file_common,
  api_order_confirm_download
} from '@/api/api'
import {getAction,postAction,postFormAction,downFileGet} from '@/api/tool'
import Vue from 'vue'

export default {
  name:'Enquiry',
  data(){
    return {
      additionalPlace:'example1@abc.com; example2@xyz.net; ...',
      formCommon:{
        caseNo:'', //这个caseNo相当于是单据编号，并不是CRM的Case No. CRM的Case No.请参考下面这个字段
        caseDate:'',
        subject:'',
        description:'',
        crmEmailCopyTo:''
      },
      rules:{
        subject:[{required:true,trigger:'blur',message:'Subject is required'}],
        description:[{required:true,trigger:'blur',message:'Description is required'}]
      },
      fileList:[] //文件列表
    }
  },
  computed:{
    uploadApi(){
      return api_claim_upload_file
    },
    //这几个值，因为是从vuex里面取的。刷新页面的时候会消失，所以不能放在formCommon里面。只能向后端提交数据的时候再塞进去
    customerName(){
      return this.$store.state.userInfo.customerName
    },
    customerAccountNo(){
      return this.$store.state.userInfo.customerAccountNo
    },
    contactName(){
      return this.$store.state.userInfo.username
    },
    contactEmail(){
      return this.$store.state.userInfo.email
    }
  },
  methods:{
    /* 单据首次进入默认赋值 */
    setDefaultValue(){
      this.formCommon.caseDate = this.getNowDate()
    },
    /* 上传附件的请求 */
    uploadRequest(params){
      this.allLoading=true
      postFormAction(api_claim_upload_file,params).then(()=>{
        this.allLoading=false
        this.$message.success('Upload successful.')
        this.getDetail().then(res=>{
          this.fileList=res.files
        })
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 上传附件的按钮 */
    handleUpload(file){
      //限制文件大小为5MB
      const maxSize = 5 * 1024 * 1024; // bytes
      if(file.file.size>maxSize){
        this.$message.warning('The size of each individual file cannot exceed 5MB.')
        return
      }
      let params = {
        flag:1,//第几个类型的claim
        file:file.file,
        keyId:this.formCommon.caseNo
      }
      if(!this.formCommon.caseNo){
        this.getCaseNo().then(res=>{
          this.formCommon.caseNo=res
          params.keyId=res
          this.uploadRequest(params)
        })
      }else{
        this.uploadRequest(params)
      }
    },
    /* 获取详情(含附件列表) */
    getDetail(){
      let params = {
        flag:1, //第几个类型的claim
        caseNo:this.formCommon.caseNo
      }
      this.allLoading=true
      return new Promise((resolve,reject)=>{
        getAction(api_claim_detail,params).then(res=>{
          this.allLoading=false
          resolve(res)
        }).catch(res=>{
          this.allLoading=false
          reject(res)
        })
      })
    },
    /* 删除附件 */
    handleDeleteFile(obj){
      let params = {
        fileId:obj.fileId
      }
      this.allLoading=true
      postAction(api_delete_file_common,params).then(()=>{
        this.$message.success('Delete successfully')
        this.allLoading=false
        this.getDetail().then(res=>{
          this.fileList=res.files
        })
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 下载附件 */
    handleDownFile(obj){
      let params = {
        filezIds:obj.filezId
      }
      this.allLoading=true
      getAction(api_order_confirm_download,params).then(res=>{
        res.forEach((val)=>{
          let url='/'+val.split(Vue.config.YUNPAN_URL)[1]
          url=url.replace('PDF?','pdf?')
          downFileGet(url).then(res=>{
            this.allLoading=false
            this.downloadFile(res)
          })
        })
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 准备执行提交请求 */
    implement(flag){
      let params = {
        ...this.formCommon,
        lineDtoList:this.productList,
        fileList:this.fileList,
        customerName:this.customerName,
        customerAccountNo:this.customerAccountNo,
        contactName:this.contactName,
        contactEmail:this.contactEmail,
        formType:flag //1说明是正常的单据
      }
      //没有case no.就先获取case no.
      if(!this.formCommon.caseNo){
        this.getCaseNo().then(res=>{
          this.formCommon.caseNo=res
          params.caseNo=res
          this.addRequest(flag,params)
        })
      }else{
        //有case no.就直接请求
        this.addRequest(flag,params)
      }
    },
    /* 提交的请求 */
    addRequest(flag,params){
      this.allLoading=true
      postAction(api_claim_enquiry_add,params).then(()=>{
        this.allLoading=false
        this.$message.success('Operation successful')
        if(flag==1){
          //跳转到正常的详情页面，这个1代表正常单据，非草稿单据
          this.goEnquiryDetail(this.formCommon.caseNo)
        }
        //传进来的参数是2，但当前的参数是draft，说明就要去到草稿页面了。否则就说明本身就是在草稿页面的，那就不用跳了
        if(flag==2 && this.$route.query.from=='normal'){
          this.goEnquiry2('draft',this.formCommon.caseNo)
        }
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 邮箱校验 */
    validateEmails(input) {
      if(!input){
        return
      }
      const emailArr = input.split(';');
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      for (const email of emailArr) {
        if (!emailRegex.test(email.trim())) {
          return false;
        }
      }
      return true;
    },
    /* 提交的按钮,1正常,2草稿 */
    handleSubmit(flag){
      //提交的数据需要校验必填的字段
      if(flag==1){
        this.$refs['formCommon'].validate((valid)=>{
          //校验不通过，请填写必填的数据
          if(!valid){
            this.$message.warning('Please fill in the required fields.')
            return
          }
          //邮箱存在就校验，不存在就不校验
          if(this.formCommon.crmEmailCopyTo){
            //必填的校验通过以后，先把中文的分号替换成英文的分号，再校验一下邮箱的规则：1.邮箱是否符合标准 2.是否用分号隔开的
            this.formCommon.crmEmailCopyTo=this.formCommon.crmEmailCopyTo.replace('；','; ')
            if(!this.validateEmails(this.formCommon.crmEmailCopyTo)){
              this.$message.warning('Please review format of Additional Emails.')
              return
            }
          }
          //校验通过
          this.implement(flag)
        })
      }
      //存草稿的数据不需要校验，直接执行
      if(flag==2){
        this.implement(flag)
      }
    }
  },
  mounted(){
    this.setDefaultValue()
    if(this.$route.query.from=='draft'){
      this.formCommon.caseNo=this.$route.query.draftNo
      this.getDetail().then(res=>{
        this.formCommon.subject = res.subject
        this.formCommon.description = res.description
        this.formCommon.crmEmailCopyTo=res.crmEmailCopyTo
        this.fileList=res.files
      })
    }
  }
  
}
</script>

<style lang="less" scoped>
@import url('/public/css/public.less');
@import url('./index.less');
</style>