<template>
  <div v-loading="allLoading">
    <!-- banner -->
    <a 
      class="banner-wrap" 
      :href="$store.state.bannerInfo.linkUrl || 'javascript:;'" 
      :target="$store.state.bannerInfo.linkUrl?'_blank':''"
    >
      <img :src="$store.state.bannerInfo.imageUrl"/>
      <div class="text-wrap">
        <a
          :href="$store.state.bannerInfo.linkUrl || 'javascript:;'" 
          :target="$store.state.bannerInfo.linkUrl?'_blank':''" 
          class="text" 
          :class="{'handle-mouse':$store.state.bannerInfo.linkUrl?true:false}"
        >{{$store.state.bannerInfo.text}}</a>
      </div>
    </a>
    <!-- 大警告 -->
    <div v-if="showNotice">
      <div class="main-wrap">
        <div class="big-warning">
          <span class="iconfont icon-warning">&#xe6aa;</span>
          <div class="text">
            <div>Due to an order block you cannot submit your order. You can add products to your cart and they will be stored, but no ordering is possible until the order block is removed.</div>
            <div>Please contact your local Customer Service representative.</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 公告 -->
    <div>
      <div 
        class="main-wrap announcement"
        v-show="$store.state.bannerInfo.notice && $store.state.bannerInfo.notice.trim() && $store.state.bannerInfo.noticeShow==1"> 
        <span class="text">{{$store.state.bannerInfo.notice}}</span>
        <div class="left-line"></div>
        <div class="right-line"></div>
      </div>
    </div>
    <!-- 热卖产品 -->
    <div class="main-wrap hot">
    <!-- <div class="main-wrap hot"> -->
      <div class="title hot-title-width">
        <div class="text">
          <!-- 热卖产品 -->
          {{L.spotlightProduct}}
          <div class="line"></div>
        </div>
      </div>
      <!-- 产品 -->
      <div class="product">
        <!-- 产品框框 -->
        <div class="square-wrap square-wrap-width">
          <div class="square spot-light-width" v-for="item in spotlightList" :key="item.familyPimid">
            <div class="pic">
              <img :src="item.eanImage"/>
            </div>
            <div class="small-title">{{item.eanName}}</div>
            <div class="content">
              <div class="content-line">
                <!-- <div class="short-line">-</div> -->
                <div class="text">{{item.description}}</div>
              </div>
            </div>
            <div class="more-info">
              <span class="iconfont icon-arrow">&#xe6ad;</span>
              <!-- 更多信息 -->
              <a 
                class="text"
                :href="detailUrl(3,item.eanPimid)"
              >{{L.moreInfo}}</a>
            </div>
          </div>
        </div>
        <!-- 快速下单 -->
        <div class="quick-wrap quick-order-width" v-show="$cookies.get('token')">
          <!-- 快速订购 -->
          <div class="quick-order">
            <div class="quick-title">{{L.quickOrder}}</div>
            <!-- 输入我们目录中的产品代码以实现快速订购。 -->
            <div class="remark">{{L.enterToQuick}}</div>
            <div class="inp-wrap">
              <textarea type="text" class="input-common" v-model="quickOrder" :placeholder="quickOrderPlacholder"/>
            </div>
            <div class="btn-wrap" @click="addToCart">
              <span class="iconfont icon-cart">&#xe62c;</span>
              {{L.addCart}}
            </div>
          </div>
          <div class="beside-two-img" v-for="item in $store.state.besideImgs" :key="item.homeRightId">
            <div class="img-wrap1" v-show="item.imageUrl">
              <img :src="item.imageUrl"/>
              <div class="on-text-wrap">
                <a 
                  class="one-text" 
                  :href="item.linkUrl || 'javascript:;'" 
                  :target="item.linkUrl?'_blank':''"
                  :class="{'handle-mouse':item.linkUrl?true:false}"
              >{{item.text}}</a>
              </div>
            </div>
            <div class="img-wrap2" v-show="!item.imageUrl">
              <a 
                :href="item.linkUrl || 'javascript:;'" 
                :target="item.linkUrl?'_blank':''"
                :class="{'handle-mouse':item.linkUrl?true:false}" 
                class="one-text"
              >{{item.text}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新产品 暂时隐藏 -->
    <div class="main-wrap new" v-if="false">
      <div class="title">
        <div class="text">
          <!-- 新产品 -->
          {{L.newProduct}}
          <div class="line"></div>
        </div>
      </div>
      <div class="square-wrap" @mouseover="mouseover" @mouseout="mouseout">
        <div class="left-circle" @click="handleLeftArrow">
          <span class="iconfont icon-arrow">&#xe66b;</span>
        </div>
        <div class="right-circle" @click="handleRightArrow">
          <span class="iconfont icon-arrow">&#xe66c;</span>
        </div>
        <div class="row"
          v-for="item,i in newProductList" 
          :key="i" 
          :class="{'play-animation':nowShowIndex===i?true:false}" 
          v-show="nowShowIndex===i?true:false"
        >
          <a
            :href="detailUrl(item2.familyPimid)"
            class="pic-col" 
            v-for="item2 in item" 
            :key="item2.familyPimid" 
          >
            <div class="pic">
              <img :src="item2.familyImage"/>
            </div>
            <div class="text">
              <span>{{item2.headlineLean}}</span>
            </div>  
          </a>
        </div>
      </div>
    </div>
    <!-- 产品目录 暂时隐藏 -->
    <div class="main-wrap catalogue" v-if="false">
      <div class="title">
        <div class="text">
          <!-- 产品目录 -->
          {{L.productCata}}
          <div class="line"></div>
        </div>
      </div>
      <div class="popover main-wrap">
        <el-row class="top" :gutter="24" type="flex" justify="start">
          <el-col :xs="8" :sm="8" :md="8" :lg="6" class="square-col" v-for="item in catalogueList" :key="item.categoryPimid">
            <div class="square" @click="chooseFirstCatalogue(item)" :class="{'square-active':item.categoryPimid==chooseFirstCataId?true:false}">{{item.categoryName}}</div>
          </el-col>
        </el-row>
        <div class="bottom-wrap">
          <div class="no-text" v-show="catalogueProductList.length==0">
            <NoContent/>
          </div>
          <el-row class="bottom" :gutter="24" type="flex" justify="start" v-show="catalogueProductList.length>0">
            <el-col 
              :xs="8" 
              :sm="8" 
              :md="8" 
              :lg="6" 
              class="pic-col"
              v-for="item in catalogueProductList" :key="item.categoryPimid"
            >
              <a class="pic-wrap" :href="goodListUrl(1,item.categoryPimid)">
                <div class="pic">
                  <img :src="item.categoryImage"/>
                </div>
                <div class="text">
                  <span>{{item.categoryName}}</span>
                </div>
              </a>   
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <AddCartErr ref="AddCartErr" />
    <AddCartResult ref="AddCartResult" />
  </div>
</template>

<script>
import AddCartErr from '@/components/AddCartErr'
import AddCartResult from '@/components/AddCartResult'
import {api_catalogue,api_quick_order,api_spotlight_product,api_new_product} from '@/api/api'
import {getAction,postAction} from '@/api/tool'
import NoContent from '@/components/NoContent'

import 'animate.css';

export default {
  name:'Catalogue',
  components:{
    NoContent,
    AddCartErr,
    AddCartResult
  },
  data(){
    return {
      // ouList:['OU-PL','OU-NL','OU-UK','OU-SK','OU-DE','OU-IT'], //这些OU的要展示公告
      catalogueList:[],//目录列表
      chooseFirstCataId:'',//选择的一级目录的id
      catalogueProductList:[],//目录产品列表
      nowShowIndex:0, //新产品当前展示的索引
      timer:null, //定时器
      innerWidth:'', //在重组图片之前的视口宽度
      quickOrder:'', //quickOrder内容
      spotlightList:[],//热门产品列表
      allNewProductList:[],//新产品数据-后端拿到的结构（未分组）
      newProductList:[], //新产品-处理过的数据
      quickOrderPlacholder:'4051111111111 120\n4052222222222 25\n4053333333333 80'
    }
  },
  computed:{
    showNotice(){
      //最外层的判断是为了让includes方法不要报错，否则会报错的
      if(this.$store.state.userInfo.accountRules){
        if(this.$store.state.userInfo.accountRules.includes('OTN')){
          return false
        }else{
          if(this.$store.state.userInfo.accountRules.includes('CSB')){
            return false
          }else{
            return true
          }
        }
      }else{
        return false
      }
    }
  },
  methods:{
    /* 快速加入购物车 */
    addToCart(){
      let params={
        quickContent:this.quickOrder
      }
      this.allLoading=true
      postAction(api_quick_order,params).then(res=>{
        this.allLoading=false
        this.getCartCount()
        //0说明全部成功
        if(res.errFlag==0){
          this.$refs.AddCartResult.open(res.errFlag)
          this.quickOrder=''
        //1说明部分成功,2说明失败
        }else{
          this.$refs.AddCartResult.open(res.errFlag,res.errList)
        }
      }).catch(res=>{
        this.allLoading=false
        this.$message.error(res.msg)
      })
    },
    /* 新产品移入 */
    mouseover(){
      clearInterval(this.timer)
    },
    /* 新产品移出 */
    mouseout(){
      this.beginTimer()
    },
    /* 开启定时器，走点击右右箭头的方法 */
    beginTimer(){
      this.timer=setInterval(()=>{
        this.handleRightArrow()
      },4000)
    },
    /* 新产品左箭头 */
    handleLeftArrow(){
      if(this.nowShowIndex===0){
        this.nowShowIndex=this.newProductList.length-1
      }else{
        this.nowShowIndex--
      }
    },
    /* 新产品右箭头 */
    handleRightArrow(){
      if(this.nowShowIndex===this.newProductList.length-1){
        this.nowShowIndex=0
      }else{
        this.nowShowIndex++
      }
    },
    /* 添加一行 */
    // addMore(){
    //   this.arr.push({id:new Date().getTime(),val:0})
    // },
    /* 删除一行 */
    // handleDelete(id){
    //   let relatedIndex ;
    //   this.arr.forEach((val,i)=>{
    //     if(val.id===id){
    //       relatedIndex=i
    //     }
    //   })
    //   this.arr.splice(relatedIndex,1)
    // },
    /* 获取目录分类列表 */
    getCatalogue(){
      getAction(api_catalogue).then(res=>{
        this.catalogueList=res
        if(res.length>0){
          this.chooseFirstCataId=res[0].categoryPimid
          this.catalogueProductList=res[0].productCategoryVoList
        }
      })
    },
    /* 点击一级目录 */
    chooseFirstCatalogue(item){
      this.chooseFirstCataId=item.categoryPimid
      this.catalogueProductList=item.productCategoryVoList
    },
    /* 对后端的数据重新处理成4个一组或者3个一组 */
    handleImgList(){
      let dataList=this.allNewProductList
      let newDataList = []
      let current = 0
      let gruopNum = window.innerWidth>=1220?4:3 //一组有几张图片
      this.innerWidth=window.innerWidth
      if(dataList && dataList.length>0){
        for(let i=0;i<=dataList.length-1;i++){
          if(i%gruopNum !== 0 || i === 0 ){
            if(!newDataList[current]){
              newDataList.push([dataList[i]])
            }else{
              newDataList[current].push(dataList[i])
            }
          }else{
            current++
            newDataList.push([dataList[i]])
          }
        }
      }
      if(newDataList.length>0){
        let lastArr = newDataList[newDataList.length-1] //新数组中的最后一组值
        let diffNum = gruopNum-lastArr.length//差值，一组的图片数减去最后一组值的个数
        if(diffNum>=1){
          for(let i=0; i<diffNum; i++){
            lastArr.push(dataList[i])
          }
        }
        newDataList[newDataList.length-1]=lastArr
      }
      this.newProductList = [...newDataList]
    },
    /* 监听页面宽度进行图片的分组处理 */
    addEventHandlePic(){
      window.addEventListener("resize", ()=>{
        /* 如果现在的大于等于1220，但是之前的小于1220，说明不一致，就要处理 */
        if(window.innerWidth>=1220 && this.innerWidth<1220){
          this.handleImgList()
        }
        /* 如果现在的小于1220，但是之前的大于等于1220，说明不一致，就要处理 */
        if(window.innerWidth<1220 && this.innerWidth>=1220){
          this.handleImgList()
        }
      });
    },
    /* 获取热门产品 */
    getSpotlight(){
      getAction(api_spotlight_product).then(res=>{
        this.spotlightList=res
      })
    },
    /* 获取新产品，并开启定时器 */
    getNewProduct(){
      getAction(api_new_product).then(res=>{
        this.allNewProductList=res
        this.handleImgList()
        this.beginTimer()
      })
    }
  },
  mounted(){
    this.getCatalogue()
    // this.getNewProduct()
    // this.addEventHandlePic()
    this.getSpotlight()
  },
  // beforeDestroy(){
  //   clearInterval(this.timer)
  // }
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('./index.less');
</style>
