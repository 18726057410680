<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap good-detail" v-show="eanList.length>0">
      <!-- 标题 -->
      <div class="family-title" v-show="familyName" v-html="familyName"></div>
      <!-- 搜索结果提示文本 -->
      <div class="search-result" v-show="show.searchResultText">
        <!-- 都找到（未找到结果的，在缺省页那边提示的，并不在这里）-->
        <div v-show="searchResult.successNums>0">
          {{searchResult.successNums}} {{L.matchResult}} &lt;&lt; {{searchResult.successProducts}} &gt;&gt;
        </div>
        <!-- 部分找到 -->
        <template v-if="searchResult.successNums>0 && searchResult.failNums>0">
          <div>
            {{searchResult.failNums}} {{L.cannotFind}} &lt;&lt; {{searchResult.failProducts}} &gt;&gt;
          </div>
          <div class="cart-line">
            <div class="remind">{{L.noSearchRemind}}</div>
            <div class="btn-wrap">
              <span class="btn" @click="showQuickOrder">{{L.quickOrder}}</span>
            </div>
          </div>
        </template>
      </div>
      <!-- 产品 -->
      <div class="product-wrap">
        <div class="product-choose product-list-width" v-for="item,i in eanList" :key="item.eanPimid">
          <div class="left">
            <div class="pic">
              <span class="iconfont icon-magnifier" @click="showBigImg(item)">&#xe622;</span>
              <img :src="item.chooseImg"/>
            </div>
            <!-- 滑动选图片 -->
            <div class="scroll">
              <div class="arrow-left" @click="movePic(1,i,item)">
                <span class="iconfont icon-left-arrow" :class="{'icon-left-arrow-not-allowed':item.leftNotAllowed}">&#xe685;</span>
              </div>
              <div class="img-wrap">
                <ul ref="picUl" :data-eanPimid="item.eanPimid">
                  <li 
                    v-for="item2,i2 in item.loopImageList" 
                    :key="i2" 
                    @click="chooseMoveImg(item,item2)" 
                    :class="{'li-active':item.chooseImg===item2}"
                  >
                    <img :src="item2"/>
                  </li>
                </ul>
              </div>
              <div class="arrow-right" @click="movePic(2,i,item)">
                <span class="iconfont icon-right-arrow" :class="{'icon-right-arrow-not-allowed':item.rightNotAllowed}">&#xe687;</span>
              </div>
            </div>
            <!-- 下载sheet -->
            <div class="download-sheet" v-if="item.dataSheetLink">
              <span class="iconfont icon-file">&#xe60a;</span>
              <!-- 产品数据表 -->
              <span class="text" @click="downloadProduct(item.dataSheetLink,1)">{{L.productDatasheet}}</span>
              <span class="iconfont icon-download" @click="downloadProduct(item.dataSheetLink,0)">&#xe61c;</span>
            </div>
            <!-- 下载欧盟产品信息表 -->
            <div class="download-sheet" v-if="item.eprelLink">
              <span class="iconfont icon-file">&#xe621;</span>
              <!-- 欧盟产品信息表 -->
              <a class="text" :href="item.eprelLink" target="_blank">{{L.EUSheet}}</a>
              <!-- <span class="text" @click="downloadProduct(item.eprelLink,1)">{{L.EUSheet}}</span>
              <span class="iconfont icon-download" @click="downloadProduct(item.eprelLink,0)">&#xe61c;</span> -->
            </div>
            <!-- 下载此产品的软件 -->
            <div class="download-sheet" v-if="item.softLinks && item.softLinks.length>0">
              <span class="iconfont icon-file">&#xe621;</span>
              <!-- 下载此产品的软件 -->
              <a target="_blank" class="text text2" :href="item.softLinks[0]">{{L.softwareDownload}}</a>
            </div>
            <!-- 小图标 -->
            <div class="small-pic">
              <img class="img img1" :src="item.energyImage && item.energyImage.logoImage" v-show="item.energyImage && item.energyImage.logoImage"/>
              <img class="big-img" :src="item.energyImage && item.energyImage.bigImage"/>
              <img class="img" v-for="item2 in item.logoImageList" :key="item2" :src="item2"/>
            </div>
            <!-- Predecessor,Successor -->
            <div class="predecessor" v-if="item.predecessorList.length>0">
              <span class="pre-title">Predecessor:</span>
              <div class="desc" v-for="item4,i4 in item.predecessorList" :key="item4.eanPimid">
                <span v-if="i4!=0">,</span>
                <a v-if="item4.canJump" class="can-jump" :href="detailUrl(1,item4.eanPimid)">{{item4.eanPimid}}</a>
                <span class="can--not-jump" v-if="!item4.canJump">{{item4.eanPimid}}</span>
              </div>
            </div>
            <div class="predecessor successor" v-if="item.successorList.length>0">
              <span class="pre-title">Successor:</span>
              <div class="desc" v-for="item4,i4 in item.successorList" :key="item4.eanPimid">
                <span v-if="i4!=0">,</span>
                <a v-if="item4.canJump" class="can-jump" :href="detailUrl(1,item4.eanPimid)">{{item4.eanPimid}}</a>
                <span class="can--not-jump" v-if="!item4.canJump">{{item4.eanPimid}}</span>
              </div>
            </div>
            <!-- link跳转, more info -->
            <div class="link-jump">
              <span class="iconfont icon-info">&#xe6ad;</span>
              <a class="text" :href="goEcatalog(item)" target="_blank">More info</a>
            </div>
          </div>
          <div class="right">
            <!-- 选择框 -->
            <div class="select">
              <div class="choose">
                <div class="text">
                <div class="text1">
                  <div class="ean-name">
                    <span v-html="item.eanName"></span>
                    <span class="predecessor-text" v-if="item.hasSuccessor">(Predecessor)</span>
                  </div>
                  <span class="phase-out" v-if="item.phaseOut">({{item.phaseOut}})</span>
                </div>
                  <div class="text2">EAN: {{item.eanPimid}}</div>
                </div>
              </div>
            </div>
            <!-- 回退到family页面 -->
            <div class="back-to-family">
              <a class="in-box" :href="goodListUrl(2,item.familyPimid)">
                <span class="text">{{L.productFamily}}</span>
                <span class="iconfont icon-arrow">&#xe687;</span>
              </a>
            </div>
            <!-- 装箱信息 -->
            <div class="package-wrap">
              <div class="package" v-for="item3,i in item.qtyMsgList" :key="i">
                <div class="content">{{item3.split(':')[0]}}:</div>
                <div class="num">{{item3.split(':')[1]}}</div>
              </div>
            </div>
            <div v-show="$cookies.get('token') && $store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('ATC')">
              <!-- 数量选择 价格 -->
              <div class="num-price">
                <div class="num">
                  <!-- 注意这里是双向数据绑定最终把值给到子组件的input的，所以这里必须要用v-model才可以，否则不会触发的 -->
                  <AddNumber 
                    paddingTopBottom="9px" 
                    v-model="item.inpVal" 
                    :step="item.countAscii" 
                    @blur="e=>blurCount(e,item)" 
                    @click="e=>blurCount(e,item)" 
                  />
                </div>
                <div class="price">
                  <!-- 申请价格的图标 -->
                  <div v-show="item.price==='unRequest'" class="price-icon">
                    <el-tooltip
                      effect="dark"
                      placement="top"
                    >
                      <div slot="content">Check price & stock</div>
                      <div 
                          :class="{'no-allow':$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('NOC')}"
                          class="icon-wrap" 
                          @click="handlePrice"
                          v-if="$store.state.userInfo.accountRules && ($store.state.userInfo.accountRules.includes('SP') || $store.state.userInfo.accountRules.includes('SAC'))"
                        >
                        <span
                          v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SP')" 
                          class="iconfont icon1"
                          :class="{'icon1-border-right':$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SP') && $store.state.userInfo.accountRules.includes('SAC')}"
                        >&#xe64a;</span>
                        <span 
                          v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SAC')" 
                          class="iconfont icon2"
                        >&#xe63e;</span>
                      </div>
                    </el-tooltip>
                  </div>
                  <!-- 价格的结果 -->
                  <div v-show="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SP')">
                    <!-- 没价格或者价格为0,都展示Price on request(unRequest的布尔值是true，所以不冲突) -->
                    <div v-show="!item.price">
                      <!-- 价格需要申请 -->
                      <span class="price-request">{{L.priceRequest}}</span>
                    </div>
                    <!-- 既不是false也不是unRequest那就肯定是有价格的 -->
                    <div v-show="item.price && item.price!=='unRequest'">
                      <div class="price-detail-wrap">
                        <el-tooltip
                          v-if="item.priceMsg"
                          effect="dark"
                          placement="top"
                        >
                        <div slot="content">{{item.priceMsg}}</div>
                          <span class="iconfont icon-info">&#xed50;</span>
                        </el-tooltip>
                        <span class="price-detail">{{formatMoney(item.totalPrice,item.customerCurrency)}}</span>
                      </div>
                      <div class="per-price">({{formatMoney(item.price,item.currency)}} per {{item.packType}})</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 可用库存 -->
              <div class="available">
                <div class="ava-left">
                  <template v-if="item.price!='unRequest'">
                    <span class="available-title">
                      {{L.availableQuantity}}:
                      <template v-if="$store.state.userInfo.accountRules && $store.state.userInfo.accountRules.includes('SAC')">
                        {{item.availableQty}}
                      </template>
                    </span>
                    <el-tooltip 
                      class="item" 
                      effect="dark"
                      placement="top"
                    >
                    <div slot="content">{{item.stockDescription}}</div>
                      <span class="iconfont icon-info">&#xed50;</span>
                    </el-tooltip>
                  </template>
                  </div>
                <div class="ava-right">
                  <!-- <span @click="showWishlist(item)" class="iconfont icon-wishlist" v-show="!item.showWishlistIcon">&#xe8b9;</span> -->
                  <el-tooltip 
                      class="item" 
                      effect="dark"
                      placement="top"
                    >
                    <div slot="content">Add to Wishlist</div>
                      <span @click="showWishlist(item)" class="iconfont icon-wishlist">&#xe60e;</span>
                    </el-tooltip>
                  <div class="wishlist-wrap" v-show="item.showWishlistIcon">
                    <WishlistSelect ref="WishlistSelect" @closeWishlistDialogue="closeWishlistDialogue"/>
                  </div>
                </div>
              </div>
              <!-- 添加到购物车 -->
              <div class="add" @click="addToCart(item)">
                <span class="iconfont">&#xe62c;</span>
                &nbsp;
                {{L.addCart}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 缺省页 -->
    <div class="main-wrap no-return-data" v-show="eanList.length==0">
      <div class="search-result">
        <!-- 未找到结果 -->
        <div class="text">{{L.sorryNotFind}} &lt;&lt; {{searchResult.failProducts}} &gt;&gt;</div>
        <div class="cart-line">
          <div class="remind">{{L.noSearchRemind}}</div>
          <div class="btn-wrap">
            <span class="btn" @click="showQuickOrder">{{L.quickOrder}}</span>
          </div>
        </div>
        <div class="predecessor-available" v-if="searchResult.successorList && searchResult.successorList.length>0">
          <div class="suggested-successor">Suggested successor available: </div>
          <div class="ean-id-text" v-for="item,i in searchResult.successorList" :key="item.eanPimid">
            <span v-if="i!=0">,</span>
            <a v-if="item.canJump" class="can-jump" :href="detailUrl(1,item.eanPimid)">{{item.eanPimid}}</a>
            <span class="can--not-jump" v-if="!item.canJump">{{item.eanPimid}}</span>
          </div>
        </div>
      </div>
      <NoContent/>
    </div>
    <!-- 大图对话框 -->
    <el-dialog
      :visible.sync="show.dialogVisible"
      :lock-scroll="false"
      :close-on-click-modal="false"
      width="70%"
      custom-class="big-src"
    >
      <div class="big-img-title">{{bigTitle}}</div>
      <img :src="bigSrc"/>
    </el-dialog>
    <QuickOrder ref="QuickOrder" @addRequest="addRequest"/>
    <AddCartErr ref="AddCartErr" />
    <AddCartResult ref="AddCartResult" />
  </div>
</template>

<script>
import AddNumber from '@/components/AddNumber'
import NoContent from '@/components/NoContent'
import WishlistSelect from '@/components/WishlistSelect'
import QuickOrder from '@/components/QuickOrder'
import AddCartErr from '@/components/AddCartErr'
import AddCartResult from '@/components/AddCartResult'

import {
  api_ean_list,
  api_add_to_cart,
  api_detail_product_price,
  api_detail_one_product,
  api_search_product,
  api_detail_from_home,
  api_wishlist_list,
  api_quick_order
} from '@/api/api'
import {getAction,postAction,downFileGet,postFormAction} from '@/api/tool'
import Vue from 'vue'

export default {
  name:'Detail',
  components:{
    AddNumber,
    NoContent,
    WishlistSelect,
    QuickOrder,
    AddCartErr,
    AddCartResult
  },
  data(){
    return {
      show:{
        dialogVisible:false,//是否展示放大图片的对话框
        searchResultText:false //搜索结果的文本提示
      },
      familyName:'',//family的name(标题)
      eanList:[],//ean列表
      bigSrc:'', //大图链接
      bigTitle:'', //大图标题
      searchResult:{ //搜索结果
        successNums:0, //成功的数量
        failNums:0, //失败的数量
        successProducts:'', //成功的id
        failProducts:'' //失败的id
      },
      wishlistList:[],
      maxScrollPic:window.innerWidth>=1220?3:4 //卷起来的图,能看见的图片张数
    }
  },
  watch:{
    //监听路由变化，获取新的搜索列表的数据
    $route(newVal){
      //注意这个地方修改代码要和mounted代码同步进行修改
      let queryArr = Object.keys(newVal.query)
      if(queryArr.includes('familyPimid')){
        this.getEanList()
      }else if(queryArr.includes('searchQuery')){
        this.getSearchList()
      }else if(queryArr.includes('eanId')){
        this.getDetail()
      }else if(queryArr.includes('eanIdFromHome')){
        this.getDetailFromHome()
      }
    }
  },
  methods:{
    goEcatalog(item){
      if(this.$store.state.area=='APAC'){
        return `https://products-apac.inventronicsglobal.com/${item.ecatalogLink}`
      }else{
        return `https://products.inventronicsglobal.com/${item.ecatalogLink}`
      } 
    },
    /* 获取ean列表 */
    getEanList(){
      let params = {
        familyPimid :this.$route.query.familyPimid 
      }
      this.show.searchResultText=false
      this.allLoading=true
      getAction(api_ean_list,params).then(res=>{
        this.allLoading=false
        this.familyName=res.headlineLean
        this.eanList=res.eanDetailList
        this.eanList.forEach((val)=>{
          val.chooseImg=val.loopImageList?val.loopImageList[0]:null //大图(主图)，自己添加的字段【注意！！这个地方要跟下面3个方法的代码一起改】
          // val.inpVal=val.countAscii //自己添加的字段【注意！！这个地方要跟下面3个方法的代码一起改】
          val.inpVal=val.moq //自己添加的字段【注意！！这个地方要跟下面3个方法的代码一起改】
          val.timeFlag=false //定时器（图片滑动）【注意！！这个地方要跟下面3个方法的代码一起改】
          val.price='unRequest'//单价【注意！！这个地方要跟下面3个方法的代码一起改】
          val.leftNotAllowed=true //【注意！！这个地方要跟下面3个方法的代码一起改】
          val.rightNotAllowed=val.loopImageList?val.loopImageList.length<=this.maxScrollPic:true //【注意！！这个地方要跟下面3个方法的代码一起改】
        })
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 获取某一个ean的信息，从购物车页面点进来的查看详情的意思 */
    getDetail(){
      let params = {
        eanOrIc  :this.$route.query.eanId 
      }
      this.show.searchResultText=false
      this.allLoading=true
      postFormAction(api_detail_one_product,params).then(res=>{
        this.allLoading=false
        this.eanList=[]
        this.familyName=''
        let obj=res.eanDetail
        //先判断是不是null，是null就默认给个空对象，不是null就直接取数组的第一项
        obj.chooseImg=obj.loopImageList?obj.loopImageList[0]:null //大图(主图)，自己添加的字段【注意！！这个地方要跟下面3个方法的代码一起改】
        // obj.inpVal=obj.countAscii //自己添加的字段【注意！！这个地方要跟下面3个方法的代码一起改】
        obj.inpVal=obj.moq //自己添加的字段【注意！！这个地方要跟下面3个方法的代码一起改】
        obj.timeFlag=false //定时器（图片滑动）【注意！！这个地方要跟下面3个方法的代码一起改】
        obj.price='unRequest'//单价【注意！！这个地方要跟下面3个方法的代码一起改】
        obj.leftNotAllowed=true //【注意！！这个地方要跟下面3个方法的代码一起改】
        //先判断loopImageList是不是null。再进一步判断
        obj.rightNotAllowed= obj.loopImageList?obj.loopImageList.length<=this.maxScrollPic:true//【注意！！这个地方要跟下面3个方法的代码一起改】
        this.eanList.push(obj)
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 获取搜索的列表，这个方法会因为home页搜索按钮的点击而触发 */
    getSearchList(){
      let params={
        queryParam:this.$route.query.searchQuery
      }
      this.show.searchResultText=false
      this.allLoading=true
      getAction(api_search_product,params).then(res=>{
        this.allLoading=false
        this.show.searchResultText=true
        this.eanList=[]
        this.familyName=''
        this.searchResult=res.searchResult //这个只有搜索的结果有，其他不需要的
        console.log(this.searchResult,'searchResult')
        res.productList.forEach((val)=>{
          let obj=val.eanDetail
          obj.chooseImg=obj.loopImageList?obj.loopImageList[0]:null //大图(主图)，自己添加的字段【注意！！这个地方要跟下面3个方法的代码一起改】
          // obj.inpVal=obj.countAscii //自己添加的字段【注意！！这个地方要跟下面3个方法的代码一起改】
          obj.inpVal=obj.moq //自己添加的字段【注意！！这个地方要跟下面3个方法的代码一起改】
          obj.timeFlag=false //定时器（图片滑动）【注意！！这个地方要跟下面3个方法的代码一起改】
          obj.price='unRequest'//单价【注意！！这个地方要跟下面3个方法的代码一起改】
          obj.leftNotAllowed=true //【注意！！这个地方要跟下面3个方法的代码一起改】
          obj.rightNotAllowed=obj.loopImageList?obj.loopImageList.length<=this.maxScrollPic:true //【注意！！这个地方要跟下面3个方法的代码一起改】
          this.eanList.push(obj)
        })
      }).catch(()=>{
        this.allLoading=false
        //注意失败要把数据清空
        this.eanList=[]
      })
    },
    /* 获取某一个ean的信息，从主页面点进来的查看详情的意思 */
    getDetailFromHome(){
      let params = {
        eanPimid  :this.$route.query.eanIdFromHome 
      }
      this.show.searchResultText=false
      this.allLoading=true
      postAction(api_detail_from_home,params).then(res=>{
        this.allLoading=false
        this.eanList=[]
        this.familyName=''
        let obj=res.eanDetail
        obj.chooseImg=obj.loopImageList?obj.loopImageList[0]:null //大图(主图)，自己添加的字段【注意！！这个地方要跟下面3个方法的代码一起改】
        // obj.inpVal=obj.countAscii //自己添加的字段【注意！！这个地方要跟下面3个方法的代码一起改】
        obj.inpVal=obj.moq //自己添加的字段【注意！！这个地方要跟下面3个方法的代码一起改】
        obj.timeFlag=false //定时器（图片滑动）【注意！！这个地方要跟下面3个方法的代码一起改】
        obj.price='unRequest'//单价【注意！！这个地方要跟下面3个方法的代码一起改】
        obj.leftNotAllowed=true //【注意！！这个地方要跟下面3个方法的代码一起改】
        obj.rightNotAllowed=obj.loopImageList?obj.loopImageList.length<=this.maxScrollPic:true //【注意！！这个地方要跟下面3个方法的代码一起改】
        this.eanList.push(obj)
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 点击获取价格的按钮 */
    handlePrice(){
      if(this.$store.state.userInfo.accountRules && this.$store.state.userInfo.accountRules.includes('NOC')){
        return
      }
      let eanIdArr=[]
      this.eanList.forEach((val)=>{
        eanIdArr.push(val.eanPimid)
      })
      let params={
        eanStrList:eanIdArr
      }
      this.allLoading=true
      postAction(api_detail_product_price,params).then(res=>{
        this.allLoading=false
        this.eanList.forEach((val)=>{
          res.forEach((val2)=>{
            if(val2.eanPimid===val.eanPimid){
              val.availableQty=val2.availableQty
              val.listPriceConversion=val2.listPriceConversion //单价
              val.totalPrice=val2.listPriceConversion*val.inpVal //总价
              val.price=val2.price //per的价格
              val.currency=val2.currency //per的币种
              val.stockDescription=val2.stockDescription //库存的notice
              val.priceMsg=val2.priceMsg //价格的提示
            }
          })
        })
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 选中滑动的图片 */
    chooseMoveImg(item,item2){
      this.eanList.forEach((val,i)=>{
        if(item.eanPimid===val.eanPimid){
          val.chooseImg=item2
          this.$set(this.eanList,i,val) //用这个才能深层嵌套数据刷新页面，否则页面渲染不会更新的
        }
      })
    },
    /* 图片向左向右滑动 */
    movePic(flag,i,item){
      //图片的数量大于能看到的图片的数量，才需要滚动
      if(!item.loopImageList || item.loopImageList.length<=this.maxScrollPic){
        return
      }
      //注意这里在滚动的时候会稍微有点精度的偏差（肉眼仔细看还是能看出来的）
      let step=71 //注意这个70是li的宽度加上距离右边的距离 67+4=71
      let picUl = this.$refs.picUl[i]
      let left = getComputedStyle(picUl,null)['transform'].substring(7).split(',')[4]
      if(item.timeFlag===false){
        item.timeFlag=true
        if(flag===1){
          /* 精度问题只有小数问题才会有，整数是没有精度问题的，所以这里不需要用toFixd等工具 */
          let finalLeft=(Number(left)+step)
          if(finalLeft>0){
            finalLeft=0 //因为left必须是负数才行，left最大值只能是0
          }
          //---处理左边箭头开始---
          if(finalLeft<0){
            item.leftNotAllowed=false
          }else{
            item.leftNotAllowed=true
          }
          //---处理左边箭头结束---
          //---处理右边箭头开始---
          if(Math.abs(finalLeft)<(item.loopImageList.length-this.maxScrollPic)*step){
            item.rightNotAllowed=false
          }else{
            item.rightNotAllowed=true
          }
          this.$set(this.eanList,i,item) //触发渲染
          //---处理右边箭头结束---
          picUl.style.transform = `translate(${finalLeft}px)`
          picUl.style.transitionDuration = "0.8s"
          setTimeout(()=>{
            item.timeFlag=false
          },900)
        }
        if(flag===2){
          /* 精度问题只有小数问题才会有，整数是没有精度问题的，所以这里不需要用toFixd等工具 */
          let finalLeft=(left-step)
          if(finalLeft<-(item.loopImageList.length-this.maxScrollPic)*step){
            finalLeft=-(item.loopImageList.length-this.maxScrollPic)*step
          }
          //---处理左边箭头开始---
          if(finalLeft<0){
            item.leftNotAllowed=false
          }else{
            item.leftNotAllowed=true
          }
          //---处理左边箭头结束---
          //---处理右边箭头开始---
          if(Math.abs(finalLeft)<(item.loopImageList.length-this.maxScrollPic)*step){
            item.rightNotAllowed=false
          }else{
            item.rightNotAllowed=true
          }
          this.$set(this.eanList,i,item) //触发渲染
          //---处理右边箭头结束---
          picUl.style.transform = `translate(${finalLeft}px)`
          picUl.style.transitionDuration = "0.8s"
          setTimeout(()=>{
            item.timeFlag=false
          },900)
        }
      }
    },
    /* 监听页面宽度对maxScrollPic进行修改 */
    addEventPageWidth(){
      window.addEventListener("resize", ()=>{
        this.maxScrollPic=window.innerWidth>=1220?3:4
      });
    },
    /* 修改数量 */
    blurCount(e,item){
      let num = e
      let countAscii = item.countAscii
      let moq = item.moq

      num = num%countAscii==0?num:(Math.floor(num/countAscii+1))*countAscii
      // num = num>=countAscii?num:countAscii
      num = num>=moq?num:moq
      
      this.eanList.forEach((val,i)=>{
        if(val.eanPimid===item.eanPimid){
          val.inpVal=num
          val.totalPrice=val.listPriceConversion*val.inpVal
          this.$set(this.eanList,i,val) //触发渲染
        }
      })
    },
    /* 展示大图 */
    showBigImg(item){
      this.show.dialogVisible=true
      this.bigSrc=item.chooseImg
      this.bigTitle=item.eanName
    },
    /* 添加到购物车 */
    addToCart(item){
      if(item.inpVal==0){
        this.$message.warning(this.L.quantityCannot0)
        return
      }
      let params = {
        skuId:item.eanPimid,
        skuCount:item.inpVal
      }
      this.allLoading=true
      postAction(api_add_to_cart,params).then(()=>{
        this.allLoading=false
        this.$message.success(this.L.operateSuccess)

        this.getCartCount()
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 下载产品文件 */
    downloadProduct(src,flag){
      let url=src.includes('inventronicsglobal')?src.split(Vue.config.DAM_URL)[1]:src.split(Vue.config.OSR_URL)[1]
      url=this.decodeHtml('/'+url)
      this.allLoading=true
      downFileGet(url).then(res=>{
        this.allLoading=false
        this.downloadFile(res,flag)
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 选择wishlist */
    showWishlist(item){
      let arr = [item]
      this.eanList.forEach((val,i)=>{
        val.showWishlistIcon=false
        if(val.eanPimid==item.eanPimid){
          val.showWishlistIcon=true
          this.$set(this.eanList,i,val) //触发渲染
          this.$refs.WishlistSelect[i].setNowProductItem(arr,this.wishlistList) //打开whishlist对话框
        }
      })
    },
    /* 关闭wishlist对话框 */
    closeWishlistDialogue(arr){
      this.eanList.forEach((val,i)=>{
        if(val.eanPimid==arr[0].eanPimid){
          val.showWishlistIcon=false
          this.$set(this.eanList,i,val) //触发渲染
        }
      })
    },
    /* 获取wishlist列表的数据 */
    getWishlistList(){
      let params = {
        isShare:1
      }
      getAction(api_wishlist_list,params).then(res=>{
        this.wishlistList=res
      })
    },
    /* 快速下单 */
    showQuickOrder(){
      this.$refs.QuickOrder.showQuickOrder=true
    },
    /* 快速加入购物车,子组件触发本组件的这个方法 */
    addRequest(){
      let params={
        quickContent:this.$refs.QuickOrder.quickOrder
      }
      this.$refs.QuickOrder.loadingQuick=true
      postAction(api_quick_order,params).then(res=>{
        this.$refs.QuickOrder.loadingQuick=false
        this.getCartCount() //获取购物车总数
        //0说明全部成功
        if(res.errFlag==0){
          this.$refs.AddCartResult.open(res.errFlag)
          this.$refs.QuickOrder.closeQuickOrder()
        //1说明部分成功,2说明失败
        }else{
          this.$refs.AddCartResult.open(res.errFlag,res.errList)
        }
      }).catch(res=>{
        this.$refs.QuickOrder.loadingQuick=false
        this.$message.error(res.msg)
      })
    },
  },
  mounted(){
    this.goTop() //进来的时候回到顶部一下
    this.addEventPageWidth()
    //存在token的话，获取一下whishlist列表的数据
    if(this.$cookies.get('token')){
      this.getWishlistList()
    }
    //注意这个地方修改代码要和watch监听$route里的代码同步进行修改
    let queryArr = Object.keys(this.$route.query)
    //正常进来的
    if(queryArr.includes('familyPimid')){
      this.getEanList()
    }else if(queryArr.includes('searchQuery')){
      //搜索进来的
      this.getSearchList()
    }else if(queryArr.includes('eanId')){
      //购物车查看产品详情进来的
      this.getDetail()
    }else if(queryArr.includes('eanIdFromHome')){
      this.getDetailFromHome()
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../../public/css/public.less');
@import url('./index.less');
</style>