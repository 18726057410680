// const baseURL = "/dev"; //本地调试,这个api是为了区分代理自己加的，不是后端的，ht是后端的
const baseURL = "/ht"; //发布测试或者正式环境

const webshop_prefix = baseURL + '/osramshop-service-webshop'
const product_prfix = baseURL + '/osramshop-service-product'


/* 语言 */
const api_languageList = webshop_prefix + '/webshopLanguages/getWebshopLanguageList'
const api_languagePackage = webshop_prefix + '/webshopLanguagesMenu/getWebshopLanguagePack'

/* 国家 */
const api_country_list = product_prfix + '/ouCountry/getCountryList'

/* 登录 */
const api_account_activation = webshop_prefix + '/api/customerOperation/customerActiveValid' //用户邮箱激活
const api_change_password = webshop_prefix + '/api/customerOperation/customerActiveValidSetPassword'
const api_send_change_password_address = webshop_prefix + '/api/customerOperation/resetPassword' //注册页面，发送邮件发送更换密码的链接
const api_login = webshop_prefix + '/api/customer/login'
const api_logout = webshop_prefix + '/api/customer/logout'
const api_is_accept_terms_privacy = webshop_prefix + '/api/customerOperation/editPolicyAccept'
const api_user_info = webshop_prefix + '/api/customerOperation/getLoginCustomer'
const api_login_url_token = webshop_prefix + '/api/customer/doCrmLogin'
const api_is_active = webshop_prefix + '/api/customerOperation/isAccoutActive'

/* 条款 */
const api_terms = webshop_prefix + '/webshopLanguagesArticle/getWebshopLanguagesArticleSingle'
const api_gtc_link = webshop_prefix + '/gtc/getGtcList'

/* 客户信息 */
const api_cus_info = webshop_prefix + '/account/myAccountInfo'
const api_cus_address_info = webshop_prefix + '/customerAddress/getCustomerAddress'
const api_led_customer_list = webshop_prefix + '/api/orderCart/getLedCustomerAddress'
// const api_customer_list = webshop_prefix + '/api/customer/getCustomerList'
const api_customer_list = webshop_prefix + '/api/customer/getMixCustomerList'
// const api_customer_change = webshop_prefix + '/api/customer/getOneCustomer'
const api_customer_change = webshop_prefix + '/api/customer/setSelectedCustomer'

/* 购物车 */
const api_quick_order = webshop_prefix + '/api/orderCart/quickAddCart'//快速下单
const api_add_to_cart = webshop_prefix + '/api/orderCart/addCart'
const api_cart_list = webshop_prefix + '/api/orderCart/getCartList'
const api_cart_split = webshop_prefix + '/api/orderCart/splitCartLine'
const api_cart_delete = webshop_prefix + '/api/orderCart/removeCart'
const api_cart_delete_quote = webshop_prefix + '/api/orderCart/removeCartForQuote'
const api_cart_update_date = webshop_prefix + '/api/orderCart/updateCartDate'
const api_cart_update_count = webshop_prefix + '/api/orderCart/updateCartCount'
const api_cart_update_all_date = webshop_prefix + '/api/orderCart/updateCartAllDate' 
const api_cart_clear_all = webshop_prefix + '/api/orderCart/clearCart' 
const api_cart_address = webshop_prefix + '/api/orderCart/getCartInfoForSet'
const api_cart_count = webshop_prefix + '/api/orderCart/getCartCount'
const api_download_cart = webshop_prefix + '/api/orderCart/exportCart'
const api_cart_check_price = webshop_prefix + '/api/orderCart/checkCart'
const api_place_order = webshop_prefix + '/api/orderCart/checkOutOrder'

/* 购物车LED */
const api_cart_save_LED = webshop_prefix + '/api/orderCart/saveLedCartInfo'
const api_cart_delivery_split_LED = webshop_prefix + '/api/orderCart/splitLedCartLine'

/* 产品 */
const api_catalogue = product_prfix + '/productCategory/getProductCategoryC1'
const api_family_list = product_prfix + '/productCatalogue/getProductCategoryFamily'
const api_family_list_price = product_prfix + '/productCatalogue/getProductCategoryFamilyWithPriceAndAvailableQty'
const api_ean_list = product_prfix + '/productCatalogue/getFamilyProductEan'
const api_spotlight_product = product_prfix + '/spotlight/getSpotlights'
const api_new_product = product_prfix + '/productCatalogue/getNewProducts'
const api_search_product = product_prfix + '/productSearch/search'
const api_detail_product_price = product_prfix + '/productSearch/searchWithPriceAndAvailableQty'
const api_detail_one_product = webshop_prefix + '/api/orderCart/getProductInfo'
const api_family_list_by_search = product_prfix + '/productSearch/search_family'
const api_family_list_by_search_price = product_prfix + '/productSearch/search_family_WithPriceAndAvailableQty'
const api_family_list_by_product = product_prfix + '/productCatalogue/getProductCategoryFamilyByFamilyId'
const api_family_list_by_product_price = product_prfix + '/productCatalogue/getProductCategoryFamilyByFamilyIdWithPriceAndAvailableQty'
//这个是从联想云盘下载附件专用的接口
const api_order_confirm_download = webshop_prefix + '/orderFile/download'
const api_beside_img = webshop_prefix + '/home/right/getRightInfo'
const api_home_banner = webshop_prefix + '/home/banner/getBannerInfo'
const api_detail_from_home = product_prfix + '/productSearch/getProductInfo'
const api_search_dropdown = product_prfix + '/productSearch/searchPrompt'

/* 收藏夹 */
const api_wishlist_list = product_prfix + '/wishlist/listWishlist'
const api_wishlist_add = product_prfix + '/wishlist/addWishlist'
const api_add_to_wishlist = product_prfix + '/wishlist/addWishlistWithDetail'
const api_wishlist_set_default = product_prfix + '/wishlist/setWishlistDefault'
const api_wishlist_clone = product_prfix + '/wishlist/cloneWishlist'
const api_wishlist_delete = product_prfix + '/wishlist/deleteWishlist'
const api_wishlist_detail = product_prfix + '/wishlist/wishlistDetail'
const api_wishlist_edit = product_prfix + '/wishlist/editWishlist'
const api_wishlist_edit_product_num = product_prfix + '/wishlist/modifyWishDetailQty'
const api_wishlist_download_list = product_prfix + '/wishlist/downloadWishlistExcel'
const api_wishlist_change_share = product_prfix + '/wishlist/shareWishlist'
const api_wishlist_delete_a_product = product_prfix + '/wishlist/deleteWishlistDetail'

/* 订单 */
const api_order_list = webshop_prefix + '/ebsToEshopInfo/getOrderInfoVo'
const api_order_detail = webshop_prefix + '/ebsToEshopInfo/getOrderDetail'
const api_order_list_download = webshop_prefix + '/ebsToEshopInfo/exportOrderOverview'
const api_order_list_detail_download = webshop_prefix + '/ebsToEshopInfo/exportOrderDetail'
const api_order_select_data = webshop_prefix + '/ebsToEshopInfo/getOrderSearchTerm'

/* 报价单 */
const api_quotation_cart = webshop_prefix + '/api/quote/askQuote'
const api_quotation_wishlist = webshop_prefix + '/api/quote/askQuoteWishlist'
const api_quote_list = webshop_prefix + '/api/quote/searchMyQuotes'
const api_quote_status = webshop_prefix + '/api/quote/getQuoteStatus'
const api_quote_list_download = webshop_prefix + '/api/quote/exportQuotes'
const api_quote_detail = webshop_prefix + '/api/quote/getQuoteDetail'
const api_quote_detail_download = webshop_prefix + '/api/quote/exportQuoteDetail'
const api_quote_add_to_cart = webshop_prefix + '/api/quote/addQuoteToCart'
const api_before_quote_judge = webshop_prefix + '/api/orderCart/checkCanRequestQuote'
const api_before_quote_judge_wishlist = webshop_prefix + '/api/quote/checkWishListCanRequestQuote'

/* 对账单 */
const api_account_stmt_download = webshop_prefix + '/account/getAccountStatement'
const api_account_stmt_status_list = webshop_prefix + '/account/getAccountStatementStatusList'

/* Credit Note */
const api_credit_list = webshop_prefix + '/api/notes/searchCreditNote'
const api_credit_detail = webshop_prefix + '/api/notes/getCreditNoteDetail'

/* Debit Note */
const api_debit_list = webshop_prefix + '/api/notes/searchDebitNote'
const api_debit_detail = webshop_prefix + '/api/notes/getDebitNoteDetail'

/* Invoice */
const api_invoice_list = webshop_prefix + '/api/invoice/queryInvoicePage'
const api_invoice_download_list = webshop_prefix + '/api/invoice/downloadInvoiceListExcel'
const api_invoice_detail = webshop_prefix + '/api/invoice/getInvoiceDetail'

/* RMA */
const api_claim_select = webshop_prefix + '/claim/api/getClaimSelect'
const api_claim_commercial_add = webshop_prefix + '/claim/ci/submitOrDraft'
const api_claim_get_case_no = webshop_prefix + '/claim/ce/getCaseNo'
const api_claim_upload_file = webshop_prefix + '/filez/uploadClaimFile'
const api_claim_detail = webshop_prefix + '/claim/api/claimDetail'
const api_claim_rma_list = webshop_prefix + '/claim/api/queryClaimPage'
const api_claim_field_add = webshop_prefix + '/claim/fa/submitOrDraft'
const api_claim_pro_add = webshop_prefix + '/claim/production/submitOrDraft'
const api_claim_delete = webshop_prefix + '/claim/api/deleteClaimDraft'
const api_claim_enquiry_add = webshop_prefix + '/claim/ce/submitOrDraft'
//这个是从联想云盘删除附件专用的接口
const api_delete_file_common = webshop_prefix + '/filez/deleteFile'

/* Pricelist */
const api_download_pricelist = webshop_prefix + '/account/downloadPriceList'


export {
  api_languageList,
  api_languagePackage,
  api_account_activation,
  api_change_password,
  api_send_change_password_address,
  api_login,
  api_logout,
  api_terms,
  api_is_accept_terms_privacy,
  api_user_info,
  api_cus_address_info,
  api_add_to_cart,
  api_order_list,
  api_catalogue,
  api_cus_info,
  api_quick_order,
  api_cart_list,
  api_cart_split,
  api_cart_delete,
  api_cart_update_date,
  api_cart_update_count,
  api_cart_update_all_date,
  api_cart_clear_all,
  api_cart_address,
  api_cart_count,
  api_download_cart,
  api_cart_check_price,
  api_family_list,
  api_ean_list,
  api_spotlight_product,
  api_new_product,
  api_place_order,
  api_search_product,
  api_detail_product_price,
  api_family_list_price,
  api_order_list_download,
  api_order_list_detail_download,
  api_order_detail,
  api_order_select_data,
  api_detail_one_product,
  api_family_list_by_search,
  api_family_list_by_search_price,
  api_family_list_by_product,
  api_family_list_by_product_price,
  api_country_list,
  api_order_confirm_download,
  api_beside_img,
  api_home_banner,
  api_detail_from_home,
  api_login_url_token,
  api_customer_list,
  api_customer_change,
  api_is_active,
  api_gtc_link,
  api_wishlist_list,
  api_wishlist_add,
  api_add_to_wishlist,
  api_wishlist_set_default,
  api_wishlist_clone,
  api_wishlist_delete,
  api_wishlist_detail,
  api_wishlist_edit,
  api_wishlist_edit_product_num,
  api_wishlist_download_list,
  api_wishlist_change_share,
  api_wishlist_delete_a_product,
  api_search_dropdown,
  api_quotation_cart,
  api_quotation_wishlist,
  api_quote_list,
  api_quote_status,
  api_quote_list_download,
  api_quote_detail,
  api_quote_detail_download,
  api_account_stmt_download,
  api_account_stmt_status_list,
  api_credit_list,
  api_invoice_list,
  api_invoice_download_list,
  api_invoice_detail,
  api_credit_detail,
  api_debit_list,
  api_debit_detail,
  api_claim_select,
  api_claim_commercial_add,
  api_claim_get_case_no,
  api_claim_upload_file,
  api_claim_detail,
  api_delete_file_common,
  api_claim_rma_list,
  api_claim_field_add,
  api_claim_pro_add,
  api_claim_delete,
  api_download_pricelist,
  api_claim_enquiry_add,
  api_quote_add_to_cart,
  api_cart_delete_quote,
  api_before_quote_judge,
  api_before_quote_judge_wishlist,
  api_led_customer_list,
  api_cart_save_LED,
  api_cart_delivery_split_LED
}