<template>
  <div class="quick-wrap" v-show="show" @touchmove.prevent>
    <div class="quick-order">
      <div class="icon-close" @click="close">
        <span class="iconfont icon1">&#xe614;</span>
        <span class="iconfont icon2">&#xe606;</span>
      </div>
      <div class="title">SO customer address list</div>
      <div class="search-wrap">
        <div class="square">
          <span class="text">Address:</span>
          <el-input size="mini" @keyup.enter.native="getCusAddressList" v-model="search.addressInfo" class="search-field"></el-input>
        </div>
        <div class="square">
          <span class="text">Contacts:</span>
          <el-input size="mini" @keyup.enter.native="getCusAddressList" v-model="search.addressContact" class="search-field"></el-input>
        </div>
        <div class="square">
          <span class="text">Phone:</span>
          <el-input size="mini" @keyup.enter.native="getCusAddressList" v-model="search.addressPhone" class="search-field"></el-input>
        </div>
        <div class="square">
          <div class="null" @click="reset">Reset</div>
          <div class="fill" @click="getCusAddressList">Search</div>
        </div>
      </div>
      <div class="table-wrap">
        <el-table
          border
          size="mini"
          max-height="300"
          v-loading="allLoading"
          style="width: 100%"
          :data="list"
          @row-click="rowClick"
          :row-class-name="rowClassName"
        >
          <el-table-column
            align="center"
            prop="addressCode"
            width="100"
            label="Select">
            <template slot-scope="scope">
              <span class="iconfont icon-check icon-active" v-show="tableSelectCode==scope.row.addressCode">&#xe730;</span>
              <span class="iconfont icon-check" v-show="tableSelectCode!=scope.row.addressCode">&#xe72f;</span>
            </template>
          </el-table-column>
          <el-table-column
            width="250"
            align="center"
            prop="addressInfo"
            label="Address">
          </el-table-column>
          <el-table-column
            align="center"
            prop="addressContact"
            label="Contacts">
          </el-table-column>
          <el-table-column
            align="center"
            prop="addressPhone"
            label="Phone">
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="footer" v-loading="loadingBtn">
        <el-button type="primary" class="btn" @click="handleConfirm">{{L.confirmSelect}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {api_led_customer_list} from '@/api/api'
import {postAction} from '@/api/tool'


export default {
  name:'LedCusAddressList',
  data() {
    return {
      loadingBtn:false,
      show:false,
      tableSelectCode:'',
      tableSelectObj:{},
      list:[],
      outLedLine:{}, //外面是哪一行led的产品
      search:{
        addressInfo:'',
        addressContact:'',
        addressPhone:''
      }
    }
  },
  methods:{
    rowClassName(e){
      if(this.tableSelectCode==e.row.addressCode){
        return 'selected-row';
      }
      return 'common-table-row-class'
    },
    rowClick(row){
      if(this.tableSelectCode==row.addressCode){
        this.tableSelectCode=''
        this.tableSelectObj={}
      }else{
        this.tableSelectCode=row.addressCode
        this.tableSelectObj=row
      }
    },
    /* 获取客户列表 */
    getCusAddressList(){
      this.allLoading=true
      postAction(api_led_customer_list,this.search).then(res=>{
        this.allLoading=false
        this.list=res
      }).catch(()=>{
        this.allLoading=false
      })
    },
    /* 确认选择 */
    handleConfirm(){
      if(!this.tableSelectCode){
        this.$message({
          message: 'Please select at least one customer address',
          type: 'warning'
        })
        return
      }
      this.close()
      this.$emit('getChooseAddress',this.tableSelectObj,this.outLedLine)
    },
    /* 重置参数 */
    reset(){
      this.search={
        addressInfo:'',
        addressContact:'',
        addressPhone:''
      }
    },
    open(obj){
      this.show=true
      this.outLedLine=obj
      //打开开窗，重置数据
      this.tableSelectCode=''
      this.tableSelectObj={}
      this.reset()
      this.getCusAddressList()
    },
    close(){
      this.show=false
    }
  }
}
</script>

<style lang="less">
/* 这个是表格部分的css，所以要单独给个style的标签，放在scoped里面不生效的 */
.common-table-row-class{
  &:hover{
    cursor: pointer;
  }
}
.selected-row{
  background-color: #fff0e6 !important;
}
</style>

<style lang="less" scoped>
@import url('../../public/css/public.less');
.quick-wrap{
  position: fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
  z-index: 999;
  background-color: rgba(0,0,0,0.6);
  overflow: hidden;
  .quick-order{
    // background-color: red;
    border-radius: 2px;
    position: absolute;
    &:extend(.main-box-shadow);
    width:720px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    padding:20px 30px;
    box-sizing: border-box;
    text-align: left;
    .icon-close{
      // background-color: red;
      position: absolute;
      right:14px;
      top:14px;
      .icon1{
        font-size: 24px;
        display: block;
        color:#888;
      }
      .icon2{
        font-size: 24px;
        display: none;
        color:@mainColor;
      }
      &:hover{
        cursor: pointer;
        .icon1{
          display: none;
        }
        .icon2{
          display: block;
        }
      }
    }
    .title{
      margin-bottom:10px;
      font-size: 16px;
      font-weight: 600;
    }
    .search-wrap{
      // background-color: pink;
      display: flex;
      align-items: flex-end;
      .square{
        flex:1;
        // background-color: orange;
        width:160px;
        // display: inline-block;
        margin-right: 10px;
        // margin-bottom: 10px;
        // vertical-align: bottom;
        .text{
          text-align: left;
          display: block;
          font-size: 12px;
        }
        .search-field{
          // width:160px;
          margin-top: 4px;
          // display: block;
          // background-color: red;
        }
        .null{
          font-size: 12px;
          display: inline-block;
          border:1px solid @mainColor;
          border-radius: 4px;
          background-color: #fff;
          color:@mainColor;
          padding:4px 6px;
          margin-right: 10px;
          &:extend(.transtion-time);
          &:hover{
            cursor: pointer;
            color:#fff;
            background-color: @mainColor;
            &:extend(.transtion-time);
          }
        }
        .fill{
          font-size: 12px;
          display: inline-block;
          border:1px solid @mainColor;
          border-radius: 4px;
          background-color: @mainColor;
          color:#fff;
          padding:4px 6px;
          &:extend(.transtion-time);
          &:hover{
            cursor: pointer;
            color:@mainColor;
            background-color: #fff;
            &:extend(.transtion-time);
          }
        }
      }
    }
    .table-wrap{
      margin-top: 10px;
      .icon-check{
        font-size: 19px;
        color:#ccc;
        &:hover{
          cursor: pointer;
        }
      }
      .icon-active{
        color:@mainColor;
      }
    }
    .footer{
      width:100%;
      margin-top:30px;
      .btn{
        white-space:pre-wrap;
        overflow: auto;
        width:100%;
      }
    }
  }
}

</style>